import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {TransferRequestTargetDto, TransferRequestTargetSearchDto} from '@typedefs/stock-rest';
import {copyCommonFields} from "@model/mapping-utils";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {TransferRequestTargetSearch} from "@model/search/transfer-request-target-search";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {FoodbankCache} from "@services/foodabank-cache";

@Injectable({
  providedIn: 'root'
})
export class TransferRequestTargetService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory)

  getTransferRequestTarget$(transferRequestTargetId: number, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<TransferRequestTarget> {
    return this.#httpClient.get<TransferRequestTargetDto>(`${environment.apiUrl}/transfer/request/target/${transferRequestTargetId}`)
      .pipe(
        map(transferRequestTargetDto => this.mapToTransferRequestTarget(transferRequestTargetDto, cache)),
      );
  }

  findTransferRequestTargets$(transferRequestTargetSearch: TransferRequestTargetSearch, pagination: Pagination, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<TransferRequestTarget> | undefined> {
    const transferRequestTargetSearchDto = this.mapToTransferRequestTargetSearchDto(transferRequestTargetSearch);

    return this.#httpClient.post<Page<TransferRequestTargetDto>>(`${environment.apiUrl}/transfer/request/target/search`, transferRequestTargetSearchDto, {params: pagination})
      .pipe(map(transferRequestTargetDtoPage => {
        const transferRequestTargets: TransferRequestTarget[] = transferRequestTargetDtoPage.content.map(transferRequestTargetDto => this.mapToTransferRequestTarget(transferRequestTargetDto, cache));
        return {
          ...transferRequestTargetDtoPage,
          content: transferRequestTargets
        }
      }));
  }

  transfer$(transferRequestTarget: TransferRequestTarget, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)) {
    return this.#httpClient.post<TransferRequestTargetDto>(`${environment.apiUrl}/transfer/request/target/${transferRequestTarget.id}/transfers`, {}, {})
      .pipe(
        map(transferRequestTargetDto => this.mapToTransferRequestTarget(transferRequestTargetDto, cache)),
      );
  }

  mapToTransferRequestTarget(transferRequestTargetDto: TransferRequestTargetDto, cache: FoodbankCache): TransferRequestTarget {
    const commonFields: TransferRequestTargetDto | TransferRequestTarget = copyCommonFields(transferRequestTargetDto, ['transferRequestId', 'targetWarehouseId']);

    const transferRequestId = transferRequestTargetDto.transferRequestId;
    const targetWarehouseId = transferRequestTargetDto.targetWarehouseId;
    return {
      ...commonFields,
      transferRequest: cache.transferRequestCache.get(transferRequestId),
      targetWarehouse: cache.warehouseCache.get(targetWarehouseId),
    };
  }

  mapToTransferRequestTargetSearchDto(transferRequestTargetSearch: TransferRequestTargetSearch): TransferRequestTargetSearchDto {
    const commonFields: TransferRequestTargetSearchDto | TransferRequestTargetSearch = copyCommonFields(transferRequestTargetSearch, ['transferRequest']);
    return {
      ...commonFields,
      transferRequestId: transferRequestTargetSearch.transferRequest?.id,
    };
  }

}

import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {PreparationItemSearch} from '@model/search/preparation-item-search';
import {PreparationItemService} from '@services/preparation-item.service';
import {PaginationService} from '@services/pagination.service';
import {PreparationItem} from '@model/preparation-item';
import {BehaviorSubject, distinctUntilChanged, forkJoin, map, Observable, shareReplay, switchMap} from 'rxjs';
import {Article} from '@model/article';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { WarehouseComponent } from '../../../../warehouse/warehouse.component';
import { FormsModule } from '@angular/forms';
import { ArticleComponent } from '../../../../article/article.component';
import { FeadPreparationReportTableComponent } from '../fead-preparation-report-table.component';
import { ProgressSpinnerModule } from "primeng/progressspinner";

@Component({
    selector: 'fead-preparation-article-report',
    templateUrl: './fead-article-report.component.html',
    styleUrls: ['./fead-article-report.component.scss'],
  imports: [NgIf, NgFor, WarehouseComponent, FormsModule, ArticleComponent, FeadPreparationReportTableComponent, AsyncPipe, ProgressSpinnerModule]
})
export class FeadArticleReportComponent implements OnInit, OnChanges {

  @Input()
  preparationItemSearch!: PreparationItemSearch;
  showBarCode = true;

  preparationItemSearchSubject$!: BehaviorSubject<PreparationItemSearch>;

  preparationItems$!: Observable<PreparationItem[]>;
  articlePreparationItemsMap$!: Observable<Map<Article, PreparationItem[]>>;
  articles$!: Observable<Article[]>;

  constructor(private preparationItemService: PreparationItemService,
              private paginationService: PaginationService,
  ) {
  }

  ngOnInit(): void {
    this.preparationItemSearchSubject$ = new BehaviorSubject<PreparationItemSearch>(this.preparationItemSearch);
    const reportPagination = this.paginationService.getReportPagination();
    this.preparationItems$ = this.preparationItemSearchSubject$.pipe(
      distinctUntilChanged(this.isSameSearch),
      switchMap(preparationItemSearch => this.preparationItemService.find(preparationItemSearch, reportPagination)),
      map(page => page.content),
      shareReplay(),
    );
    this.articlePreparationItemsMap$ = this.preparationItems$.pipe(
      switchMap(preparationItems => forkJoin(this.splitPreparationItemsByArticle$(preparationItems))),
      map((value: [Article, PreparationItem][]) => this.groupByArticle(value)),
      shareReplay(),
    );
    this.articles$ = this.articlePreparationItemsMap$.pipe(
      map(organizationPreparationItemsEntries => Array.from(organizationPreparationItemsEntries.keys())),
      shareReplay(),
    );
  }

  private isSameSearch(search1: PreparationItemSearch, search2: PreparationItemSearch): boolean {
    const json1 = JSON.stringify(search1);
    const json2 = JSON.stringify(search2);
    return json1===json2;
  }

  private splitPreparationItemsByArticle$(preparationItems: PreparationItem[]): Observable<[Article, PreparationItem]>[] {
    return preparationItems.map(preparationItem => {
      return this.splitPreparationItemByArticle$(preparationItem);
    })
  }

  private splitPreparationItemByArticle$(preparationItem: PreparationItem): Observable<[Article, PreparationItem]> {
    return preparationItem.article$.pipe(map(article => [article, preparationItem]));
  }

  ngOnChanges() {
    if (this.preparationItemSearchSubject$) {
      this.preparationItemSearchSubject$.next(this.preparationItemSearch);
    }
  }

  private groupByArticle<T>(articleWithPreparationItemArray: [Article, T][]): Map<Article, T[]> {
    const articlePreparationItemsMap = new Map<Article, T[]>();
    const uniqueArticles: Article[] = [];

    articleWithPreparationItemArray.forEach(([article, preparationItem]) => {
      let uniqueArticle = uniqueArticles.find(existingArticle => this.isSameArticle(existingArticle, article));
      if (!uniqueArticle) {
        uniqueArticle = article;
        uniqueArticles.push(article);
      }
      const preparationItems = articlePreparationItemsMap.get(uniqueArticle) || [];
      preparationItems.push(preparationItem);
      articlePreparationItemsMap.set(uniqueArticle, preparationItems);
    });

    return articlePreparationItemsMap;
  }

  private isSameArticle(article1: Article, article2: Article) {
    return article1?.id===article2?.id;
  }
}

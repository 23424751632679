import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'parenthesize',
  standalone: true,
})
export class ParenthesizePipe implements PipeTransform {

  transform(value?: any): string {
    return !!value ? `(${value})` : '';
  }

}

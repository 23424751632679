import {Component, Input} from '@angular/core';
import {PreparationItem} from '@model/preparation-item';
import { NgIf, NgFor, AsyncPipe, DecimalPipe } from '@angular/common';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { ArticleComponent } from '../../../article/article.component';
import { FormsModule } from '@angular/forms';
import { OrganizationComponent } from '../../../organization/organization.component';
import { NumberComponent } from '../../../number/number.component';

@Component({
    selector: 'foodbank-fead-preparation-report-table',
    templateUrl: './fead-preparation-report-table.component.html',
    styleUrls: ['./fead-preparation-report-table.component.scss'],
    imports: [NgIf, NgFor, NgxBarcode6Module, ArticleComponent, FormsModule, OrganizationComponent, NumberComponent, AsyncPipe, DecimalPipe]
})
export class FeadPreparationReportTableComponent {

  @Input()
  preparationItems!: PreparationItem[];
  @Input()
  showBarCode = true;
  @Input()
  showOrganization = true;
  @Input()
  showArticle = true;

}

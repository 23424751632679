import {Component, computed, inject, input, model, signal, Signal} from '@angular/core';
import {debounceTime, defaultIfEmpty, EMPTY, map, pipe, switchMap} from 'rxjs';
import {MultiSelectFilterEvent, MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {StockService} from "@services/stock.service";
import {InternalBatchSearch} from "@model/search/internal-batch-search";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";

@Component({
  selector: 'foodbank-internal-batch-multi-selection',
  templateUrl: './internal-batch-multi-selection.component.html',
  styleUrls: ['./internal-batch-multi-selection.component.scss'],
  imports: [
    MultiSelectModule,
    FormsModule
  ]
})
export class InternalBatchMultiSelectionComponent {

  ngModel = model<string[]>([]);
  internalBatchSearch = input<InternalBatchSearch>();

  internalBatches: Signal<string[] | undefined>
  internalBatchContains = signal<string | undefined>(undefined);

  #stockService = inject(StockService);

  constructor() {
    const debouncedInternalBatchContains = pipeSignal(this.internalBatchContains, debounceTime(300));
    const actualInternalBatchSearch = computed(() => ({
      ...this.internalBatchSearch(),
      internalBatchContains: debouncedInternalBatchContains()
    }));

    this.internalBatches = pipeSignal(actualInternalBatchSearch, pipe(
      switchMap(internalBatchSearch => !internalBatchSearch ? EMPTY : this.#stockService.findInternalBatches(internalBatchSearch, {page: 0, size: 1000})),
      map(page => page.content),
      defaultIfEmpty([]))
    );
  }

  filterInternalBatches($event: MultiSelectFilterEvent) {
    this.internalBatchContains.set($event.filter);
  }
}

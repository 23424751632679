import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateSelectionModeComponent} from '@components/date/date-selection-mode/date-selection-mode.component';
import {CalendarModule} from "primeng/calendar";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {DateSelectionMode} from "@services/date-selection.mode";

export interface FoodbankDatePickerEvent {
  value: Date | Date[] | undefined;
  utcValue: Date | Date[] | undefined;
  selectionMode: DateSelectionMode;
}

@Component({
    selector: 'foodbank-date-picker',
    templateUrl: './date-picker.component.html',
    imports: [
        CalendarModule,
        FormsModule,
        DateSelectionModeComponent,
        NgIf
    ]
})
/**
 * @deprecated use either PrimeNG Calendar or DateSearchComponent
 */
export class DatePickerComponent implements OnInit {

  @Input() ngModel: Date | Date[] | undefined;
  @Output() onSelect: EventEmitter<FoodbankDatePickerEvent> = new EventEmitter<FoodbankDatePickerEvent>();
  @Output() ngModelChange: EventEmitter<Date | Date[] | undefined> = new EventEmitter<Date | Date[] | undefined>();

  @Input() inputId: string | undefined;
  @Input() showSelectionOptions: boolean = true;
  @Input() required: boolean = true;
  @Input() showClear: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = "";
  @Input() showButtonBar = false;

  defaultDateSelectionMode = DateSelectionMode.Single;
  dateSelectionMode: DateSelectionMode = this.defaultDateSelectionMode;

  ngOnInit(): void {
    this.dateSelectionMode = !!this.inputId && this.#getSavedDateSelectionModePreference() || this.defaultDateSelectionMode;
  }

  dateRangeChanged(value: Date | Date[] | undefined) {
    let utcValue = value;

    if (value instanceof Date) {
      //convert to UTC
      utcValue = DatePickerComponent.convertToUTC(value);
      this.ngModelChange.emit(utcValue);
    }

    if (utcValue instanceof Array) {
      if (utcValue?.filter(v => !!v).length < 2) {
        return;
      }

      utcValue = utcValue.map(value => DatePickerComponent.convertToUTC(value));
    }

    this.emit({value, utcValue, selectionMode: this.dateSelectionMode});
  }

  private static convertToUTC(value: Date) {
    const utcValue = new Date(value);
    utcValue.setMinutes(utcValue.getMinutes() - utcValue.getTimezoneOffset());
    return utcValue;
  }

  private emit(event: FoodbankDatePickerEvent) {
    this.onSelect.emit(event);
    this.ngModelChange.emit(event.utcValue);
  }

  saveDateSelectionModePreference(dateSelectionMode: DateSelectionMode) {
    this.dateSelectionMode = dateSelectionMode;
    if (!!this.inputId) {
      const key = this.#getSelectionModePrefKey();

      localStorage.setItem(key, dateSelectionMode.toString() || this.defaultDateSelectionMode.toString());
    }
  }

  #getSavedDateSelectionModePreference(): DateSelectionMode | undefined {
    const key = this.#getSelectionModePrefKey();
    return localStorage.getItem(key) as DateSelectionMode | undefined;
  }

  #getSelectionModePrefKey() {
    return this.inputId + '.selection.mode';
  }

  protected readonly DateSelectionMode = DateSelectionMode;
}

import {Component, OnInit} from '@angular/core';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';
import { FeadCampaignSelectionComponent } from '../../fead-campaign/fead-campaign-selection/fead-campaign-selection.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { WeekFormatDirective } from '../../../directives/week.format.directive';
import { WeekValidatorDirective } from '../../../directives/week.validator.directive';
import { CheckboxModule } from 'primeng/checkbox';
import { FeadPlanningSummaryTableComponent } from './table/fead-planning-summary-table.component';

@Component({
    selector: 'foodbank-fead-planning-summary',
    templateUrl: './fead-planning-summary.component.html',
    styleUrls: ['./fead-planning-summary.component.scss'],
    imports: [FeadCampaignSelectionComponent, FormsModule, InputTextModule, WeekFormatDirective, WeekValidatorDirective, CheckboxModule, FeadPlanningSummaryTableComponent]
})
export class FeadPlanningSummaryComponent implements OnInit {

  feadPlanningSearch!: FeadPlanningSearch
  relevantOnly = true;

  constructor() {
  }

  ngOnInit(): void {
    const year = new Date().getFullYear();
    this.feadPlanningSearch = {
      year: year,
      startPeriod: `${year}/01`,
      endPeriod: `${year}/52`,
    }
  }

  selectCampaign(campaign: FeadCampaignIdentifier) {
    this.feadPlanningSearch = {
      ...this.feadPlanningSearch,
      year: campaign.year,
    }
  }
}

import {Component, input, model} from '@angular/core';
import {SupplierCategory} from "@model/supplier-category";
import { NgIf } from '@angular/common';
import { LanguageDirective } from '../../directives/language.directive';

@Component({
    selector: 'foodbank-supplier-category',
    templateUrl: './supplier-category.component.html',
    styleUrls: ['./supplier-category.component.scss'],
    imports: [NgIf, LanguageDirective]
})
export class SupplierCategoryComponent {

  ngModel = model<SupplierCategory>();
  showId = input(true);

  constructor() {
  }

}

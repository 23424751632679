import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FeadPlanningByArticle} from '@model/fead-planning-by-article';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {Organization} from '@model/organization';
import { NgIf, NgFor, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { PrimeTemplate } from 'primeng/api';
import { Button } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { WeekFormatDirective } from '../../../../directives/week.format.directive';
import { WeekValidatorDirective } from '../../../../directives/week.validator.directive';
import { ArticleComponent } from '../../../article/article.component';
import { FeadPlanningQuantityCellEditorComponent } from '../../fead-planning-quantity-cell-editor/fead-planning-quantity-cell-editor.component';

interface FeadOrganizationPlanningEntrySummary {
  saldo: number;
  total: number;
}

interface FeadOrganizationPlanningEntryWithSummary extends FeadPlanningByArticle {
  summary: FeadOrganizationPlanningEntrySummary;
}

interface FeadOrganizationPlanningSummary {
  plannedQuantity: number;
  shippedQuantity: number;
  total: number;
  saldo: number;
  periodQuantityMap: { [index: string]: number };
}

@Component({
    selector: 'foodbank-fead-planning-organization-table',
    templateUrl: './fead-planning-organization-table.component.html',
    styleUrls: ['./fead-planning-organization-table.component.scss'],
    imports: [NgIf, TableModule, PrimeTemplate, NgFor, Button, FormsModule, InputTextModule, WeekFormatDirective, WeekValidatorDirective, ArticleComponent, NgClass, FeadPlanningQuantityCellEditorComponent, AsyncPipe, DecimalPipe]
})
export class FeadPlanningOrganizationTableComponent implements OnInit, OnChanges {

  @Input()
  planningData!: FeadPlanningByArticle[];
  @Input()
  organization!: Organization;
  @Input()
  year!: number;
  @Output()
  dataChange = new EventEmitter<FeadPlanningByArticle[]>();
  @ViewChild('periodNameLabel') periodNameLabel: ElementRef | undefined; // to focus input element, this needs to be set

  addPeriodVisible = false;
  newPeriodName: string | undefined;

  periods: string[] = [];
  entryWithSummaryList: FeadOrganizationPlanningEntryWithSummary[] = [];
  generalSummary: FeadOrganizationPlanningSummary | undefined;

  constructor(
    private hotkeysService: HotkeysService,
  ) {
  }

  ngOnInit() {
    const addPeriodHotkey = new Hotkey('+', (event: KeyboardEvent): boolean => {
      this.showNewPeriod();
      return false; // Prevent bubbling
    });
    this.hotkeysService.add(addPeriodHotkey);
  }

  ngOnChanges(): void {
    this.load();
  }

  updateEntryPeriodQuantity(feadOrganizationPlanningEntryWithSummary: FeadOrganizationPlanningEntryWithSummary, period: string, quantity: number) {
    feadOrganizationPlanningEntryWithSummary.periodQuantityMap[period] = quantity;
    feadOrganizationPlanningEntryWithSummary.summary = this.getSummary(feadOrganizationPlanningEntryWithSummary);
    this.calcGeneralSummary();
    this.dataChange.emit(this.entryWithSummaryList);
  }

  addPeriod() {
    this.showNewPeriod();
  }

  addSelectedPeriod(newPeriodName: string) {
    if (!this.periods.includes(newPeriodName)) {
      this.periods.push(newPeriodName);
    }
    this.periods = this.periods.sort(this.comparePeriods);
    this.hideNewPeriod();
  }

  cancelAddPeriod() {
    this.hideNewPeriod();
  }

  private load() {
    this.getPeriods();
    this.getSummaries();
    this.calcGeneralSummary();
  }

  updateNewPeriodName(newPeriodName: string) {
    this.newPeriodName = newPeriodName;
  }

  private getPeriods() {
    const rawPeriods = this.planningData
      .map(entry => Object.keys(entry.periodQuantityMap))
      .flat()
      .reduce((periods: string[], period: string) => [...periods, period], [])
      .sort(this.comparePeriods);
    this.periods = Array.from(new Set(rawPeriods));
  }

  private getSummaries() {
    this.entryWithSummaryList = this.planningData.map(entry => this.getFeadOrganizationPlanningEntrySummary(entry));
  }

  private calcGeneralSummary() {
    this.generalSummary = this.entryWithSummaryList
      .reduce((currentSummary, entryWithSummary) => this.summarizeEntry(currentSummary, entryWithSummary), this.createInitialSummary());
  }

  private createInitialSummary(): FeadOrganizationPlanningSummary {
    return {
      plannedQuantity: 0,
      shippedQuantity: 0,
      total: 0,
      saldo: 0,
      periodQuantityMap: {},
    };
  }

  private summarizeEntry(summary: FeadOrganizationPlanningSummary, entry: FeadOrganizationPlanningEntryWithSummary): FeadOrganizationPlanningSummary {
    summary.plannedQuantity += entry.plannedQuantity;
    summary.shippedQuantity += entry.shippedQuantity;

    summary.total += entry.summary.total;
    summary.saldo += entry.summary.saldo;

    for (const period in entry.periodQuantityMap) {
      let summaryPeriodQuantity = summary.periodQuantityMap[period] || 0;
      summaryPeriodQuantity += entry.periodQuantityMap[period];
      summary.periodQuantityMap[period] = summaryPeriodQuantity;
    }

    return summary;
  }

  private getFeadOrganizationPlanningEntrySummary(feadOrganizationPlanningEntry: FeadPlanningByArticle): FeadOrganizationPlanningEntryWithSummary {
    return {
      ...feadOrganizationPlanningEntry,
      summary: this.getSummary(feadOrganizationPlanningEntry)
    };
  }

  private getSummary(feadOrganizationPlanningEntry: FeadPlanningByArticle): FeadOrganizationPlanningEntrySummary {
    const total = this.getTotal(feadOrganizationPlanningEntry);
    return {
      saldo: feadOrganizationPlanningEntry.plannedQuantity - total,
      total: total
    }
  }

  private getTotal(feadOrganizationPlanningEntry: FeadPlanningByArticle): number {
    const periodQuantityMap = feadOrganizationPlanningEntry.periodQuantityMap;
    const quantitySum = Object.values(periodQuantityMap)
      .reduce((runningSum, quantity) => runningSum + quantity, 0);
    const shippedQuantity = feadOrganizationPlanningEntry.shippedQuantity;
    return quantitySum + shippedQuantity;
  }

  private showNewPeriod() {
    this.addPeriodVisible = true;
    this.newPeriodName = this.year + '/';
    setTimeout(() => this.periodNameLabel?.nativeElement?.click(), 0); // set focus to input, this is the only working way I found
  }

  private hideNewPeriod() {
    this.addPeriodVisible = false;
    this.newPeriodName = undefined;
  }

  private comparePeriods(period1: string, period2: string): number {
    // extract year and week from the strings
    const [year1, week1] = period1.split("/");
    const [year2, week2] = period2.split("/");

    // compare years
    const yearComparison = parseInt(year1) - parseInt(year2);

    if (yearComparison!==0) {
      // years are different, done
      return yearComparison;
    }

    // compare weeks
    return parseInt(week1) - parseInt(week2);
  }
}

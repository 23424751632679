import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'phone' })
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    const simplifiedValue = value.replace(/\(0\)/g, '');
    const digitsOnlyValue = simplifiedValue.replace(/[^0-9]/g, '');

    return digitsOnlyValue;
  }

}

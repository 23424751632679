import {Component, Input} from '@angular/core';

@Component({
    selector: 'foodbank-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent {

  @Input() language: string | undefined | null;
}

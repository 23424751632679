import { Component, Input, model, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Returnable } from '@model/returnable';
import { ReturnableSearch } from '@model/search/returnable-search';
import { BehaviorSubject, distinctUntilChanged, map, mergeMap, Observable, tap } from 'rxjs';
import { ReturnableService } from '@services/returnable.service';
import { LanguageDirective } from "@directives/language.directive";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule } from "@angular/forms";
import { PrimeTemplate } from "primeng/api";
import { ReturnableComponent } from "@components/returnable/returnable.component";
import { AsyncPipe, NgIf } from "@angular/common";

@Component({
    selector: 'foodbank-returnable-single-selection',
    templateUrl: './returnable-single-selection.component.html',
    styleUrl: './returnable-single-selection.component.scss',
    imports: [LanguageDirective, DropdownModule, FormsModule, PrimeTemplate, ReturnableComponent, NgIf, AsyncPipe]
})
export class ReturnableSingleSelectionComponent implements OnInit, OnChanges {

  ngModel = model<Returnable>();
  @Input()
  returnableSearch: ReturnableSearch | undefined; // you could for example create a returnable filter to get returnables of a specific user

  returnableSearch$!: BehaviorSubject<ReturnableSearch | undefined>;

  returnables$: Observable<Returnable[]> | undefined;

  loading: boolean = false;

  constructor(private returnableService: ReturnableService) {
  }

  ngOnInit(): void {
    this.returnableSearch$ = new BehaviorSubject<ReturnableSearch | undefined>(this.returnableSearch);
    this.returnables$ = this.returnableSearch$.pipe(
      distinctUntilChanged(),
      tap(() => this.loading = true),
      mergeMap(returnableSearch => this.returnableService.findReturnable$(returnableSearch || {}, {page: 0, size: 1000})),
      map(page => page.content),
      tap(() => this.loading = false)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['returnableSearch']) {
      this.searchReturnables();
    }
  }

  select(selectedReturnable: Returnable | undefined) {
    this.ngModel.set(selectedReturnable);
  }

  clear() {
    this.select(undefined);
  }

  private searchReturnables() {
    if (this.returnableSearch && this.returnableSearch$) {
      this.returnableSearch$.next(this.returnableSearch);
    }
  }
}

import { Component, EventEmitter, Input, model, OnChanges, OnInit, Output } from '@angular/core';
import {BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap} from 'rxjs';
import {Page} from '@typedefs/page';
import {FeadPlanningPagination, FeadPlanningSummaryService} from '@services/fead-planning-summary.service';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import {StockFeadPlanning} from '@model/stock-fead-planning';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';
import {UserService} from '@services/user.service';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {Warehouse} from '@model/warehouse';
import { PrimeTemplate } from 'primeng/api';
import { NgFor, NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { ArticleComponent } from '../../../article/article.component';
import { FormsModule } from '@angular/forms';
import { WarehouseComponent } from '../../../warehouse/warehouse.component';

@Component({
    selector: 'foodbank-fead-planning-summary-table',
    templateUrl: './fead-planning-summary-table.component.html',
    styleUrls: ['./fead-planning-summary-table.component.scss'],
    imports: [TableModule, PrimeTemplate, NgFor, NgIf, ArticleComponent, FormsModule, WarehouseComponent, AsyncPipe, DecimalPipe]
})
export class FeadPlanningSummaryTableComponent implements OnInit, OnChanges {

  @Input()
  feadPlanningSearch?: FeadPlanningSearch
  @Input()
  relevantOnly = true;

  @Output()
  warehousesSelected: EventEmitter<Warehouse[]> = new EventEmitter<Warehouse[]>();
  @Output()
  periodsSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  loading = model(true);
  selectedWarehouse?: Warehouse;
  selectedPeriod?: string;

  feadPlanningSearchSubject$!: BehaviorSubject<FeadPlanningSearch>;
  selectedWarehouseSubject$!: BehaviorSubject<Warehouse | undefined>;
  selectedPeriodSubject$!: BehaviorSubject<string | undefined>;
  pagination$!: BehaviorSubject<FeadPlanningPagination>;

  sanitizedFeadPlanningSearch$!: Observable<FeadPlanningSearch>;

  stockFeadPlanningPage$: Observable<Page<StockFeadPlanning>> | undefined;
  periods$: Observable<string[]> | undefined;

  constructor(
    private feadPlanningSummaryService: FeadPlanningSummaryService,
    private paginationService: PaginationService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    const pagination = this.paginationService.getDefaultPagination();
    this.pagination$ = new BehaviorSubject<FeadPlanningPagination>(pagination);
    this.feadPlanningSearchSubject$ = new BehaviorSubject<FeadPlanningSearch>(this.feadPlanningSearch || {});
    this.selectedWarehouseSubject$ = new BehaviorSubject<Warehouse | undefined>(this.selectedWarehouse);
    this.selectedPeriodSubject$ = new BehaviorSubject<string | undefined>(this.selectedPeriod);
    this.sanitizedFeadPlanningSearch$ = combineLatest([this.feadPlanningSearchSubject$, this.selectedWarehouseSubject$, this.selectedPeriodSubject$]).pipe(
      distinctUntilChanged(),
      switchMap(([feadPlanningSearch, warehouse, period]) => this.sanitizeFeadPlanningSearch$(feadPlanningSearch, warehouse, period))
    );
    this.stockFeadPlanningPage$ = combineLatest([this.pagination$, this.sanitizedFeadPlanningSearch$]).pipe(
      distinctUntilChanged(),
      switchMap(([pagination, feadPlanningSearch]) => {
        this.loading.set(true);
        return this.feadPlanningSummaryService.getFeadPlanning$(feadPlanningSearch, this.relevantOnly, pagination);
      }),
      shareReplay(1)
    );
    this.periods$ = this.stockFeadPlanningPage$.pipe(
      map(page => page.content),
      map(stockFeadPlannings => stockFeadPlannings.flatMap(stockFeadPlanning => Array.from(stockFeadPlanning.periodPlanningQuantityMap.keys()))),
      map(arrayOfArrayOfPeriods => arrayOfArrayOfPeriods.flat()),
      map(periods => new Set(periods)),
      map(periods => Array.from(periods)),
      map(periods => periods.filter(period => !!period)),
      map(periods => periods.sort()), // Sort the periods
      tap(_ => this.loading.set(false))
    );
  }

  ngOnChanges(): void {
    this.clearSelection();
    this.feadPlanningSearchSubject$?.next(this.feadPlanningSearch || {});
  }

  private clearSelection() {
    this.selectPeriod();
    this.selectWarehouse();
  }

  private sanitizeFeadPlanningSearch$(unsanitizedFeadPlanningSearch?: FeadPlanningSearch, selectedWarehouse?: Warehouse, selectedPeriod?: string): Observable<FeadPlanningSearch> {
    return of(unsanitizedFeadPlanningSearch || {}).pipe(
      switchMap(feadPlanningSearch => this.getFeadPlanningSearchWithOrganization$(feadPlanningSearch)),
      map(feadPlanningSearch => ({
        ...feadPlanningSearch,
        startPeriod: this.sanitizeWeek(feadPlanningSearch.startPeriod),
        endPeriod: this.sanitizeWeek(feadPlanningSearch.endPeriod),
        warehouse: selectedWarehouse,
        period: this.sanitizeWeek(selectedPeriod),
      } as FeadPlanningSearch)),
    )
  }

  private getFeadPlanningSearchWithOrganization$(feadPlanningSearch: FeadPlanningSearch) {
    if (!feadPlanningSearch.warehouseSearch?.company) {
      return this.getFeadPlanningSearchWithDefaultCompany$(feadPlanningSearch)
    } else {
      return of(feadPlanningSearch);
    }
  }

  private getFeadPlanningSearchWithDefaultCompany$(feadPlanningSearch: FeadPlanningSearch): Observable<FeadPlanningSearch> {
    return this.userService.getCurrentUser$().pipe(
      switchMap(user => user.company$),
      map(company => {
        const warehouseSearch: WarehouseSearch = {
          ...feadPlanningSearch.warehouseSearch,
          company: company
        };
        return ({
          ...feadPlanningSearch,
          warehouseSearch: warehouseSearch
        }) as FeadPlanningSearch;
      })
    );
  }

  paginate(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination$?.next(pagination);
  }

  selectPeriod(period?: string) {
    this.selectedPeriod = period;
    this.selectedPeriodSubject$?.next(period);
    this.periodsSelected.next(period && [period] || []);
  }

  selectWarehouse(warehouse?: Warehouse) {
    this.selectedWarehouse = warehouse;
    this.selectedWarehouseSubject$?.next(warehouse);
    this.warehousesSelected.next(warehouse && [warehouse] || []);
  }

  identity(stockFeadPlanning: any): StockFeadPlanning {
    return stockFeadPlanning;
  }

  private sanitizeWeek(week?: string): string | undefined {
    if (week?.length===1) {
      return `0${week}`;
    }
    return week;
  }

  isSelectedWarehouse(warehouse?: Warehouse) {
    return warehouse?.id===this.selectedWarehouse?.id;
  }

  isSelectedPeriod(period?: string) {
    return period===this.selectedPeriod;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;
}

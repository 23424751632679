<div class="card p-fluid" *ngIf="receptionItem() as receptionItem">
  <form #form="ngForm">
    <div class="grid">
      <div class="col-6 flex flex-column">
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="article"
                 i18n>Article *</label>
          <foodbank-article-selection class="w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                                      id="article" name="article" ngDefaultControl [required]="true"
                                      [articleSearch]="articleSearch$() || undefined"
                                      [(ngModel)]="receptionItem.article.value"
                                      (ngModelChange)="selectArticle($event, receptionItem)" />
        </div>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="net-unit-weight"
                 i18n>Net unit weight (gr) *</label>
          <input *ngIf="!isBulkFood(); else naForBulkFood" class="text-xl p-1 w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                 id="net-unit-weight" name="net-unit-weight"
                 type="number" [ngModel]="receptionItem.unitWeight" (ngModelChange)="saveUnitWeight($event, receptionItem)"
                 [readOnly]="receptionItem.article.value()?.fead" min="0" ngDefaultControl required/>
        </div>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round"
                 for="gross-unit-weight" i18n>Gross unit weight (gr)</label>
          <input *ngIf="!isBulkFood(); else naForBulkFood" class="text-xl p-1 w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                 id="gross-unit-weight" name="gross-unit-weight" type="number" [(ngModel)]="receptionItem.unitGrossWeight" (ngModelChange)="save(receptionItem)"
                 [readOnly]="receptionItem.article.value()?.fead || !(receptionItem.unitWeight > 0)" min="0" ngDefaultControl required/>
        </div>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="units-per-parcel"
                 i18n>Units per parcel *</label>
          <input *ngIf="!isBulkFood(); else naForBulkFood" class="text-xl p-1 w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                 id="units-per-parcel" name="units-per-parcel"
                 type="number" [(ngModel)]="receptionItem.unitsPerParcel" (ngModelChange)="save(receptionItem)"
                 [readOnly]="receptionItem.article.value()?.fead || !(receptionItem.unitWeight > 0)" min="0" ngDefaultControl required/>
        </div>
        <ng-template #naForBulkFood>
          <span class="text-xl p-1 w-8 flex-initial flex align-items-center border-round mt-1 mb-1">N/A for bulk food</span>
        </ng-template>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="net-weight"
                 pTooltip="Net unit weight (gr) / 1000 X Units per parcel X No of pallets"
                 tooltipPosition="top" i18n>Net weight (Kg) <span *ngIf="isBulkFood()">*</span></label>
          <input class="text-xl p-1 w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                 id="net-weight" name="net-weight"
                 type="text" [ngModel]="receptionItemSummary()?.netWeight" (ngModelChange)="receptionItem.quantity = $event" ngDefaultControl
                 required [readonly]="true"/>
        </div>
      </div>
      <div class="col-6 flex flex-column">
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="description"
                 i18n>Description</label>
          <input class="text-xl p-1 w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                 id="description" name="description" ngDefaultControl
                 type="text" [maxLength]="40" [(ngModel)]="receptionItem.description" (ngModelChange)="save(receptionItem)"/>
        </div>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="supplier-lot"
                 i18n>Supplier lot</label>
          <input class="text-xl p-1 w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                 id="supplier-lot" name="supplier-lot" ngDefaultControl
                 type="text" [(ngModel)]="receptionItem.supplierLot" (ngModelChange)="save(receptionItem)"/>
        </div>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="expiration-date"
                 i18n>Expiration date *</label>
          <foodbank-date-picker class="w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                                id="expiration-date" name="expiration-date" ngDefaultControl [required]="false"
                                [ngModel]="receptionItem.expirationDate" (onSelect)="selectExpirationDate($event, receptionItem)"
                                [showClear]="true" [showSelectionOptions]="false"/>
        </div>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="best-before-date"
                 i18n>Best before date *</label>
          <foodbank-date-picker class="w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                                id="best-before-date" name="best-before-date" ngDefaultControl [required]="false"
                                [ngModel]="receptionItem.bestBeforeDate" (onSelect)="selectBestBeforeDate($event, receptionItem)"
                                [showClear]="true" [showSelectionOptions]="false"/>
        </div>
        <div class="flex flex-row">
          <label class="h-2rem w-4 flex-initial flex align-items-center font-bold m-2 border-round" for="returnable"
                 i18n>Returnable</label>
          <foodbank-returnable-single-selection class="w-8 flex-initial flex align-items-center border-round mt-1 mb-1"
                                                id="returnable" name="returnable" ngDefaultControl
                                                [(ngModel)]="receptionItem.returnable.value"
                                                (ngModelChange)="save(receptionItem)" />
        </div>
      </div>
    </div>
    <p-divider align="left" type="solid" />

    <p-card class="m-0 p-0" #palletClusterTable>
      <ng-container *ngIf="receptionItem.rank">
        <foodbank-reception-pallet-cluster-table id="pallet-cluster-table"
                                                 [editingEnabled]="true"
                                                 [receptionItem]="receptionItem" />
      </ng-container>
    </p-card>
    <p-blockUI [target]="palletClusterTable" [blocked]="!areArticleDetailsValid(receptionItem)">
      <i class="pi pi-lock text-lg text-white mr-2"></i><span class="text-lg text-white" i18n>Select an article and provide the Expiration/Best before date first!</span>
    </p-blockUI>

    <div class="field grid mt-3">
      <div class="col-offset-3 col-3"
           [pTooltip]="form.invalid! || !receptionItemDetailsAreValid() ? 'Pallets need to be defined and the form needs to be valid in order to be able to save this reception item. Please click the ➕ button.' : undefined"
      >
        <p-button label="Save"
                  icon="pi pi-check"
                  styleClass="p-button-primary"
                  [disabled]="form.invalid! || !receptionItemDetailsAreValid() || !areArticleDetailsValid(receptionItem)"
                  (onClick)="saveAndClose(receptionItem)" />
      </div>
      <div class="col-3">
        <p-button label="Close" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="closeWithoutSaving()" />
      </div>
    </div>
  </form>
</div>

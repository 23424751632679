import {Component, input, model} from '@angular/core';
import {Warehouse} from "@model/warehouse";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AsyncPipe, NgIf} from "@angular/common";
import {CompanyComponent} from "@components/company/company.component";
import {TooltipModule} from "primeng/tooltip";
import { FormsModule } from "@angular/forms";

@Component({
    selector: 'foodbank-warehouse',
    templateUrl: './warehouse.component.html',
    imports: [
        OverlayPanelModule,
        NgIf,
        CompanyComponent,
        AsyncPipe,
        TooltipModule,
        FormsModule
    ],
    styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent {

  ngModel = model<Warehouse>();
  showId = input<boolean>(false);
  showCompany = input<boolean>(false);
  showDetails = input<boolean>(true);

}

import {Directive, ElementRef, HostListener, Self} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({ selector: '[formatWeek]' })
export class WeekFormatDirective {

  constructor(private elementRef: ElementRef, @Self() private ngControl: NgControl) {
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur() {
    this.formatAndValidate();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key==='Enter') {
      this.formatAndValidate();
    }
  }

  private formatAndValidate() {
    let value: string = this.elementRef.nativeElement.value;

    if (value && /^\d{1,2}$/.test(value)) {
      const year = new Date().getFullYear();
      value = `${year}/${value}`;
    }
    let formattedValue = value.replace(/(\d{4})\/(\d{1,2})/, (match, year, week) => {
      if (parseInt(week)===0) {
        week = '01';
      }
      if (week.length===1) {
        week = '0' + week; // Add leading zero if week is a single digit
      }
      return `${year}/${week}`;
    });

    this.elementRef.nativeElement.value = formattedValue;

    // Update the form control value and trigger revalidation
    this.ngControl.control!.setValue(formattedValue);
    this.ngControl.control!.updateValueAndValidity();
  }
}

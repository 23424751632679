import {Component, Input} from '@angular/core';
import {LanguageService} from '@services/language.service';
import {map} from 'rxjs';
import {PalletType} from '@model/pallet-type';
import {AsyncPipe, NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";

@Component({
    selector: 'foodbank-pallet-type',
    templateUrl: './pallet-type.component.html',
    styleUrls: ['./pallet-type.component.scss'],
    imports: [
        AsyncPipe,
        LanguageDirective,
        NgIf
    ]
})
export class PalletTypeComponent {

  @Input()
  palletType?: PalletType;

  @Input()
  showId = true;

  showId$ = this.languageService.isCurrentLanguageDisplayable$(['nl', 'fr'])
    .pipe(map(isDisplayable => {
      if (!isDisplayable) {
        return true;
      } else {
        return this.showId;
      }
    }));

  constructor(private languageService: LanguageService) {
  }

}

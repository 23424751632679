<a [routerLink]="['/fead/preparation', preparation.id]">
  <i class="fa-solid fa-dolly mr-1"></i> Preparation <span class="id" *ngIf="showId">#{{ preparation.id }}</span> from <span class="date">{{ preparation.date | date: 'dd/MM/yyyy' }}</span>, <span class="location">{{ preparation.location }}</span>
</a>
<ng-container *ngIf="showDetails">
  <span class="details">
    <foodbank-warehouse ngDefaultControl
      [ngModel]="(preparation.warehouse$ | async) || undefined"
     />
  </span>
</ng-container>

import { Component, Input } from '@angular/core';
import { Supplier } from '@model/supplier';
import { Warehouse } from '@model/warehouse';
import { Reception } from '@model/reception';
import { ReceptionService } from '@services/reception.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, defaultIfEmpty, EMPTY, filter, map, Observable, of, shareReplay, take } from 'rxjs';
import { FoodbankDatePickerEvent } from '@components/date/date-picker/date-picker.component';
import { UserService } from '@services/user.service';
import { WarehouseSearch } from '@model/search/warehouse-search';
import { SupplierSearch } from '@model/search/supplier-search';
import { NgForm, FormsModule } from "@angular/forms";
import { NgIf, AsyncPipe } from '@angular/common';
import { SupplierSelectionComponent } from '../../supplier/selection/single/supplier-selection.component';
import { WarehouseSingleSelectionComponent } from '../../warehouse/selection/single/warehouse-single-selection.component';
import { DatePickerComponent } from '../../date/date-picker/date-picker.component';
import { Button } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

type ReceptionEditFormInput = {
  supplier?: Supplier;
  warehouse?: Warehouse;
  date?: Date | Date[];
}

@Component({
    selector: 'foodbank-reception-edit',
    templateUrl: './reception-edit.component.html',
    styleUrls: ['./reception-edit.component.scss'],
    imports: [NgIf, FormsModule, SupplierSelectionComponent, WarehouseSingleSelectionComponent, DatePickerComponent, Button, TooltipModule, AsyncPipe]
})
export class ReceptionEditComponent {

  @Input() reception?: Reception;
  supplier$: Observable<Supplier>
  warehouse$: Observable<Warehouse | undefined>

  warehouseSearch$: Observable<WarehouseSearch>;
  supplierSearch$: Observable<SupplierSearch>;

  formInput$: Observable<ReceptionEditFormInput>

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<Reception>,
              private receptionService: ReceptionService,
              private userService: UserService) {
    this.reception = this.config.data || {} as Reception;
    this.supplier$ = this.config.data?.supplier$ || EMPTY;
    this.warehouse$ = this.config.data?.warehouse$ || this.userService.getDefaultWarehouse$();

    if (this.warehouse$) {
      this.warehouse$.pipe(
        take(1),
        filter(value => !!value)
      )
        .subscribe(warehouse => this.updateWarehouse(warehouse!));
    }

    const currentUserCompany$ = this.userService.getCurrentUserCompany$().pipe(shareReplay());
    this.warehouseSearch$ = currentUserCompany$.pipe(
      map(company => ({company}))
    );

    this.supplierSearch$ = currentUserCompany$.pipe(
      map(company => ({company, includeCountrySuppliers: true}))
    );

    const date$ = of(this.reception.date);
    const supplierInput$ = this.supplier$.pipe(defaultIfEmpty(undefined));
    this.formInput$ = combineLatest([supplierInput$, this.warehouse$, date$]).pipe(
      map(([supplier, warehouse, date]) => ({supplier, warehouse, date}))
    );
  }

  save(receptionFormInput: NgForm) {

    const value = receptionFormInput.value;
    const receptionToSave: Reception = {
      ...this.reception!,
      supplier$: of(value.supplier),
      warehouse$: of(value.warehouse),
      company$: value.warehouse.company$,
      date: value.date
    };

    this.receptionService.saveReception(receptionToSave)
      .subscribe(() => this.ref.close(receptionToSave));
  }

  close() {
    this.ref.close({});
  }

  updateSupplier(supplier?: Supplier) {
    if (this.reception && supplier) {
      this.reception.supplier$ = of(supplier);
    }
  }

  updateWarehouse(warehouse?: Warehouse) {
    if (this.reception && warehouse) {
      this.reception.company$ = warehouse.company$;
      this.reception.warehouse$ = of(warehouse);
    }
  }

  updateDate(date: FoodbankDatePickerEvent) {
    if (this.reception) {
      const value = date.value as Date;
      const utcDate = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate());
      this.reception.date = new Date(utcDate)

    }
  }
}

import {Component, inject, input, model, Signal} from '@angular/core';
import {Company} from '@model/company';
import {CompanyService} from "@services/company.service";
import {Page} from "@typedefs/page";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {CompanySearch} from "@model/search/company-search";
import {derivedAsync} from "ngxtension/derived-async";
import {CompanyComponent} from "@components/company/company.component";

@Component({
  selector: 'foodbank-company-selection',
  templateUrl: './company-single-selection.component.html',
  styleUrls: ['./company-single-selection.component.scss'],
  imports: [
    DropdownModule,
    FormsModule,
    CompanyComponent,

  ]
})
export class CompanySingleSelectionComponent {

  ngModel = model<Company>();
  required = input(false);
  companySearch = input<CompanySearch>({});
  showClear = input(true);

  companyPage: Signal<Page<Company> | undefined>;

  readonly companyService = inject(CompanyService);

  constructor() {
    this.companyPage = derivedAsync(() => this.companyService.findCompanies$(this.companySearch(), {page: 0, size: 1000}));
  }

}

import {Component, computed, effect, inject, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {CalendarModule} from "primeng/calendar";
import {FormsModule} from "@angular/forms";
import {DateSearch} from "@model/search/date-search";
import {DateSelectionMode} from "@services/date-selection.mode";
import {DateSelectionModeComponent} from "@components/date/date-selection-mode/date-selection-mode.component";
import {DateService} from "@services/date.service";

const defaultDateSelectionMode = DateSelectionMode.Single;

@Component({
    selector: 'foodbank-date-search',
    templateUrl: './date-search.component.html',
    imports: [
        CalendarModule,
        FormsModule,
        DateSelectionModeComponent
    ]
})
export class DateSearchComponent {

  ngModel = model.required<DateSearch>();

  inputId = input<string>();
  showSelectionOptions = input(true);
  required = input(true);
  showClear = input(true);
  disabled = input(false);
  placeholder = input("");
  showButtonBar = input(false);

  dateSelectionMode: WritableSignal<DateSelectionMode>;

  // humans tend to mean "MIN <= day < MAX + 1" when they say "from day MIN to day MAX" (with other words, MAX must be included in search)
  // this computes a date to display with MAX + 1
  userDisplayableDateSearch: Signal<DateSearch>;

  #dateService = inject(DateService);

  constructor() {
    this.dateSelectionMode = this.#getDateSelectionMode();
    this.userDisplayableDateSearch = computed(() => this.#getUserDisplayableDateSearch(this.ngModel()));

    effect(() => {
      this.#saveDateSelectionModePreference(this.dateSelectionMode());
    });
  }

  handleExactDateSelected(date?: Date) {
    this.ngModel.set({
      exactDate: date ? this.#dateService.convertToUtcDate(date) : undefined,
    })
  }

  handleDateRangeSelected(minDate?: Date, maxDate?: Date) {
    this.ngModel.set({
      minDate: minDate ? this.#dateService.convertToUtcDate(minDate) : undefined,
      maxDate: maxDate ? this.#dateService.addDays(maxDate, 1) : undefined,
    })
  }

  handleClearDate() {
    this.ngModel.set({});
  }

  #saveDateSelectionModePreference(dateSelectionMode: DateSelectionMode) {
    const inputId = this.inputId();
    if (!!inputId) {
      this.#dateService.saveDateSelectionMode(inputId, dateSelectionMode);
    }
  }

  #getDateSelectionMode(): WritableSignal<DateSelectionMode> {
    const inputId = this.inputId();
    if (!inputId) {
      return signal(defaultDateSelectionMode);
    } else {
      const dateSelectionMode = this.#dateService.loadDateSelectionMode(inputId);
      return dateSelectionMode ? signal(dateSelectionMode) : signal(defaultDateSelectionMode);
    }
  }

  #getUserDisplayableDateSearch(sourceDateSearch: DateSearch) {
    const maxDate = sourceDateSearch.maxDate;
    const minDate = sourceDateSearch.minDate;
    const userDisplayableMaxDate = !maxDate ? undefined : this.#dateService.addDays(maxDate, -1);
    const exactDate = sourceDateSearch.exactDate;
    return ({
      minDate: minDate,
      maxDate: userDisplayableMaxDate,
      exactDate: exactDate,
    });
  }

  protected readonly DateSelectionMode = DateSelectionMode;
}

import { Component, EventEmitter, Input, model, OnChanges, Output, SimpleChanges } from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, map, mergeMap, Observable} from 'rxjs';
import {MovementType} from "@model/movement-type";
import {MovementTypeSearch} from "@model/search/movement-type-search";
import {MovementTypeService} from "@services/movement-type.service";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";

@Component({
    selector: 'foodbank-movementType-multi-selection',
    templateUrl: './movement-type-multi-selection.component.html',
    styleUrls: ['./movement-type-multi-selection.component.scss'],
    imports: [
        MultiSelectModule,
        FormsModule,
        AsyncPipe,
        NgForOf,
        NgIf,
        LanguageDirective
    ]
})
export class MovementTypeMultiSelectionComponent implements OnChanges {

  ngModel = model<MovementType[]>([]);
  @Input()
  movementTypeSearch: MovementTypeSearch | undefined;
  @Output()
  onMovementTypeSelected: EventEmitter<MovementType[]> = new EventEmitter<MovementType[]>();

  movementTypeSearch$: BehaviorSubject<MovementTypeSearch> = new BehaviorSubject<MovementTypeSearch>({})
  movementTypes$: Observable<MovementType[]> | undefined | null;

  constructor(private movementTypeService: MovementTypeService) {
    this.movementTypes$ = this.movementTypeSearch$.pipe(
      distinctUntilChanged(this.isSameSearch),
      mergeMap(movementTypeSearch => this.movementTypeService.findMovementTypes$(movementTypeSearch, {page: 0, size: 1000})),
      map(page => {
        return page.content
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['movementTypeSearch'] && this.movementTypeSearch) {
      this.movementTypeSearch$.next(this.movementTypeSearch);
    }
  }

  select(selectedMovementTypes: MovementType[]) {
    this.onMovementTypeSelected.emit(selectedMovementTypes);
  }

  clear() {
    this.onMovementTypeSelected.emit([]);
  }

  private isSameSearch(search1: MovementTypeSearch, search2: MovementTypeSearch): boolean {
    const json1 = JSON.stringify(search1);
    const json2 = JSON.stringify(search2);
    return json1 === json2;
  }
}

<h1>Fead planning by article</h1>
<div class="field grid">
  <label for="warehouse" class="col-1" i18n>Warehouse</label>
  <div class="col-3">
    <ng-container *ngIf="warehouseSearch() as warehouseSearch">
      <foodbank-warehouse-selection id="warehouse"
        [warehouseSearch]="warehouseSearch"
        [ngModel]="(warehouse$ | async) || undefined"
        (ngModelChange)="selectWarehouse($event)"
        ngDefaultControl />
    </ng-container>
  </div>
</div>

<div *ngIf="warehouse$ | async" class="field grid">
  <label for="associationType" class="col-1" i18n>Association type</label>
  <div class="col-3">
    <foodbank-fead-planning-organization-type-selection id="associationType"
      [associationType]="(associationType$ | async) || undefined"
      (associationTypeChange)="selectAssociationType($event)" />
  </div>
</div>

<div *ngIf="warehouse$ | async as warehouse" class="field grid">
  <label for="campaign" class="col-1" i18n>Campaign</label>
  <div class="col-3">
    <foodbank-fead-campaign-selection id="campaign"
      [warehouse]="warehouse"
      (onCampaignSelected)="selectCampaign($event)" />
  </div>
</div>

<div *ngIf="campaign$ | async" class="field grid">
  <label for="article" class="col-1" i18n>Article</label>
  <div class="col-3">
    <foodbank-article-selection id="article"
      [articleSearch]="articleSearch()"
      (ngModelChange)="selectArticle($event)"
     />
  </div>
</div>

<div *ngIf="article$ | async" class="field grid">
  <label for="round" class="col-1" i18n>Round</label>
  <p-dropdown id="round" class="col-3" [options]="(articleRoundOptions$ | async) || []" [ngModel]="round$ | async"
              placeholder="Select a round" (onChange)="selectRound($event.value)" />
</div>
<div class="field grid" *ngIf="article$ | async as article">
  <div class="col-3 col-min-3">
    <label for="article-net-weight" class="col-1" i18n>Net weight/unit (gr)</label>
    <span id="article-net-weight" class="font-semibold">{{ article?.bulkFood || article?.feadUnitWeight === 0 ? 'N/A' : article?.feadUnitWeight ?? 0 }}</span>
    <span class="text-gray-500"> / 1000 X</span>
  </div>
  <div class="col">
    <label for="article-parcels-per-pallet" class="col-1" i18n>⚀ Units</label>
    <span id="article-parcels-per-pallet" class="font-semibold">{{ article?.bulkFood || article?.feadUnitWeight === 0 ? 'N/A' : articleStockData().units }}</span>
  </div>
  <div class="col text-gray-500" ><span class="pl-2">=</span></div>
  <div class="col">
    <label for="article-stock-total-weight" class="col-1" i18n>🏋 Weight (Kg)</label>
    <span id="article-stock-total-weight" class="font-semibold">{{ articleStockData().quantity | number: '1.2-2' }}</span>

  </div>
</div>
<div class="field grid" *ngIf="article$ | async as article">
  <div class="col-3">
    <label for="article-units-per-parcel" class="col-1" i18n>Units/parcel</label>
    <span id="article-units-per-parcel" class="font-semibold">{{ article?.bulkFood || article?.feadUnitWeight === 0 ? 'N/A' : article?.feadUnitsPerParcel }}</span>
  </div>
  <div class="col">
    <label for="article-stock-no-of-parcels" class="col-1" i18n>📦 Parcels</label>
    <span id="article-stock-no-of-parcels" class="font-semibold">{{ article?.bulkFood || article?.feadUnitWeight === 0 ? 'N/A' : articleStockData().parcels }}</span>
  </div>
  <div class="col">
    <label for="article-units-per-pallet" class="col-1" i18n>Units/pallet</label>
    <span id="article-units-per-pallet"
          class="font-semibold">{{ article?.bulkFood || article?.feadUnitWeight === 0 ? 'N/A' : ((article?.feadUnitsPerParcel || 0) * (article?.feadParcelsPerPallet || 0)) }}</span>
  </div>
  <div class="col">
    <label for="article-stock-no-of-pallets" class="col-1" i18n>Pallets</label>
    <span id="article-stock-no-of-pallets" class="font-semibold">{{ articleStockData().pallets }}</span>
  </div>
</div>
<div class="field grid">
  <div class="col ui-toolbar">
    <p-button label="Save"
      icon="pi pi-check"
      styleClass="p-button-primary"
      (onClick)="save()"
      [disabled]="!updatedData"
     />
    <p-confirmDialog [style]="{width: '50vw'}" />
    <p-button label="Cancel"
      icon="pi pi-undo"
      styleClass="p-button-primary"
      (click)="this.cancel();"
      [disabled]="!(planningData$ | async)"
     />
    <p-button label="Delete"
      icon="pi pi-trash"
      styleClass="p-button-primary"
      (click)="delete()"
      [disabled]="!(planningData$ | async)"
     />
  </div>
</div>

<foodbank-fead-planning-article-table *ngIf="(planningData$ | async) as planningData"
  [planningData]="planningData"
  [year]="(campaign$ | async)?.year || 0"
  (dataChange)="planningDataUpdates($event)"
  [article]="(article$ | async) || undefined"
 />

@let singleSelectionMode = dateSelectionMode() === DateSelectionMode.Single;
@let rangeSelectionMode = dateSelectionMode() === DateSelectionMode.Range;
@let dateSearch = userDisplayableDateSearch();
@let selectionMode = singleSelectionMode ? 'single' : 'range';
@let dateRange = dateSearch.minDate && dateSearch.maxDate ? [dateSearch.minDate, dateSearch.maxDate] : undefined;
@let calenderModel = rangeSelectionMode ? dateRange : dateSearch.exactDate;
<p-calendar
  ngDefaultControl
  [ngModel]="calenderModel"
  (ngModelChange)="!$event ? handleClearDate() : (rangeSelectionMode ? handleDateRangeSelected($event[0], $event[1]): handleExactDateSelected($event))"
  [disabled]="disabled()"
  [inputId]="inputId()"
  [placeholder]="placeholder()"
  [readonlyInput]="false"
  [required]="required()"
  [selectionMode]="selectionMode"
  [showButtonBar]="showButtonBar()"
  [showClear]="showClear()"
  appendTo="body"
  class="w-12"
  dateFormat="yy-mm-dd">
  <ng-template pTemplate="header">
    @if (showSelectionOptions()) {
      <foodbank-date-selection-mode ngDefaultControl [(ngModel)]="dateSelectionMode"/>
    }
  </ng-template>
</p-calendar>

import { Component, computed, inject, input, linkedSignal, model, output, Signal, signal, WritableSignal } from '@angular/core';
import { StockService } from '@services/stock.service';
import { debounceTime, forkJoin, map, mergeMap, Observable, of, pipe } from "rxjs";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { Page } from '@typedefs/page';

import { StockSearch } from '@model/search/stock-search';
import { activeWarehouseSearchByCompany, WarehouseSearch } from '@model/search/warehouse-search';
import { ArticleSearch } from '@model/search/article-search';
import { Stock } from '@model/stock';
import { Warehouse } from '@model/warehouse';
import { Article } from '@model/article';
import { ArticleCategory } from '@model/article-category';
import { DEFAULT_ROWS_PER_PAGE, PaginationService } from '@services/pagination.service';
import { Pagination } from '@services/pagination';
import { ArticleStorageCondition } from '@model/article-storage-condition';
import { UserService } from '@services/user.service';
import { ArticleCategorySearch } from '@model/search/article-category-search';
import { ArticleStorageConditionSearch } from '@model/search/article-storage-condition-search';
import { PalletSearch } from "@model/search/pallet-search";
import { StockPallet } from "@model/stock-pallet";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { MovementDialogComponent } from "@components/movement/new-movement-dialog/movement-dialog.component";
import { Movement } from "@model/movement";
import { MovementTypeService } from "@services/movement-type.service";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { overrideSignal, pipeSignal } from "@util/foodbanks-signal-rxjs-interop";
import { MovementType } from "@model/movement-type";
import { InterWarehouseTransferService } from "@services/inter-warehouse-transfer-service";
import { InterWarehouseTransfer } from "@model/inter-warehouse-transfer";
import { MessageService } from "primeng/api";
import { MovementKind } from "@typedefs/stock-rest";
import { MovementTypeSearch } from "@model/search/movement-type-search";
import { ArticleStorageConditionSelectionComponent } from "@components/article-storage-condition/article-storage-condition-selection.component";
import { PanelModule } from "primeng/panel";
import { MovementTypeSingleSelectionComponent } from "@components/movement-type/selection/single/movement-type-single-selection.component";
import { WarehouseSingleSelectionComponent } from "@components/warehouse/selection/single/warehouse-single-selection.component";
import { Button } from "primeng/button";
import { ToggleButtonModule } from "primeng/togglebutton";
import { PaginatorModule } from "primeng/paginator";
import { TableSizeComponent } from "@components/table-size/table-size.component";
import { ArticleCategorySelectionComponent } from "@components/article-category/selection/multi/article-category-selection.component";
import { ArticleMultiSelectionComponent } from "@components/article/selection/multi/article-multi-selection.component";
import { WarehouseMultipleSelectionComponent } from "@components/warehouse/selection/multiple/warehouse-multiple-selection.component";
import { PalletSingleSelectionComponent } from "@components/pallet/selection/single/pallet-single-selection.component";
import { ArticleCategoryComponent } from "@components/article-category/article-category.component";
import {AsyncPipe, DatePipe, DecimalPipe, NgIf} from "@angular/common";
import { ArticleComponent } from "@components/article/article.component";
import { WarehouseComponent } from "@components/warehouse/warehouse.component";
import { PalletComponent } from "@components/pallet/pallet.component";
import { InputTextModule } from "primeng/inputtext";
import { InternalBatchSearch } from "@model/search/internal-batch-search";
import { InternalBatchMultiSelectionComponent } from "@components/internal-batch/selection/multi/internal-batch-multi-selection.component";
import { SupplierBatchMultiSelectionComponent } from "@components/supplier-batch/selection/multi/supplier-batch-multi-selection.component";
import { SupplierBatchSearch } from "@model/search/supplier-batch-search";
import { DragDropModule } from "primeng/dragdrop";
import { derivedAsync } from "ngxtension/derived-async";
import { StockAdjustmentService } from "@services/stock-adjustment.service";

@Component({
  selector: 'foodbank-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss'],
  imports: [
    TableModule,
    ArticleStorageConditionSelectionComponent,
    PanelModule,
    MovementTypeSingleSelectionComponent,
    WarehouseSingleSelectionComponent,
    Button,
    ToggleButtonModule,
    PaginatorModule,
    TableSizeComponent,
    ArticleCategorySelectionComponent,
    ArticleMultiSelectionComponent,
    WarehouseMultipleSelectionComponent,
    PalletSingleSelectionComponent,
    ArticleCategoryComponent,
    AsyncPipe,
    ArticleComponent,
    WarehouseComponent,
    PalletComponent,
    DecimalPipe,
    InputTextModule,
    NgIf,
    InternalBatchMultiSelectionComponent,
    SupplierBatchMultiSelectionComponent,
    DragDropModule,
    DatePipe,
  ]
})
export class StockListComponent {

  stockSearch = model<StockSearch>();
  bulkMovementKind = input<MovementKind>();
  emptyMessage = input("");
  bulkMovementTypeIsReadOnly = input(false);
  canExitBulkMovementMode = model(true);
  bulkMovementActive = model(false);
  bulkMovementType = model<MovementType>();
  bulkMovementTypeOverride: WritableSignal<MovementType | undefined>;
  bulkMovementTypeSearch = signal<MovementTypeSearch>({
    forWarehouse: true
  })
  bulkMovementNeedsTargetWarehouse: Signal<boolean>;
  bulkMovementTargetWarehouse = model<Warehouse>();
  bulkMovementComment = signal<string | undefined>(undefined);
  bulkMovementValid: Signal<boolean>;

  bulkMovementTargetWarehouseSearch: Signal<WarehouseSearch>;

  tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';
  pagination: WritableSignal<Pagination>;

  selectedStocks = input<Stock[]>([]);
  overrideSelectedStocks = overrideSignal(this.selectedStocks);

  embedded = input(false);
  unrestrictedCompany = input(false);

  // searches for stock page
  selectedWarehouses = input<Warehouse[]>([]);
  overrideSelectedWarehouses = overrideSignal(this.selectedWarehouses);
  selectedArticles = input<Article[]>([]);
  overrideSelectedArticles = overrideSignal(this.selectedArticles);
  selectedArticleCategories = input<ArticleCategory[]>([]);
  overrideSelectedArticleCategories = overrideSignal(this.selectedArticleCategories);
  selectedArticleStorageConditions = input<ArticleStorageCondition[]>([]);
  overrideSelectedArticleStorageConditions = overrideSignal(this.selectedArticleStorageConditions);
  selectedPallet = input<StockPallet | undefined>(undefined);
  overrideSelectedPallet = overrideSignal(this.selectedPallet);
  selectedInternalBatches = input<string[]>([]);
  overrideSelectedInternalBatches = overrideSignal(this.selectedInternalBatches);
  selectedSupplierBatches = input<string[]>([]);
  overrideSelectedSupplierBatches = overrideSignal(this.selectedSupplierBatches);

  onStockDragStart = output<Stock[]>();
  onStockDragEnd = output<void>();

  actualStockSearch: Signal<StockSearch>;

  stockPage: Signal<Page<Stock> | undefined>;
  // searches for filters
  filterArticleStorageConditionSearch = signal<ArticleStorageConditionSearch>({});
  filterArticleCategorySearch = signal<ArticleCategorySearch>({});
  filterWarehouseSearch: Signal<WarehouseSearch>;
  filterPalletSearch = signal<PalletSearch>({
    withStock: true,
  });

  filterArticleSearch: Signal<ArticleSearch>;
  filterInternalBatchSearch: Signal<InternalBatchSearch>
  filterSupplierBatchSearch: Signal<SupplierBatchSearch>

  fullDescriptionContains = signal<string | undefined>(undefined);

  movementDialogRef?: DynamicDialogRef;

  #dialogService = inject(DialogService);
  #stockService = inject(StockService);
  #stockAdjustmentService = inject(StockAdjustmentService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #movementTypeService = inject(MovementTypeService);
  #interWarehouseTransferService = inject(InterWarehouseTransferService);
  #messageService = inject(MessageService);

  constructor() {
    this.pagination = this.#paginationService.getDefaultPaginationSignal();

    const currentUserCompany$ = this.#userService.getCurrentUserCompany$();
    const currentUserCompany = toSignal(currentUserCompany$);

    this.filterWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
      active: true
    }));

    this.filterArticleSearch = computed(() => ({
        articleCategorySearch: this.filterArticleCategorySearch(),
        articleStorageConditionSearch: this.filterArticleStorageConditionSearch(),
      })
    );

    this.filterPalletSearch = signal({
      withStock: true,
    })

    const fullDescriptionContains$ = toObservable(this.fullDescriptionContains);
    const debouncedFullDescriptionContains$ = fullDescriptionContains$.pipe(debounceTime(500));
    const debouncedFullDescriptionContains = toSignal(debouncedFullDescriptionContains$);

    this.actualStockSearch = computed(() => ({
        ...this.stockSearch(),
        warehouseSearch: {
          warehouses: this.overrideSelectedWarehouses(),
          company: !this.unrestrictedCompany() ? currentUserCompany() : undefined,
        },
        articleSearch: {
          articles: this.overrideSelectedArticles(),
          articleCategorySearch: {
            articleCategories: this.overrideSelectedArticleCategories(),
          },
          articleStorageConditionSearch: {
            articleStorageConditions: this.overrideSelectedArticleStorageConditions(),
          }
        },
        internalBatchSearch: {
          internalBatches: this.overrideSelectedInternalBatches()
        },
        supplierBatchSearch: {
          supplierBatches: this.overrideSelectedSupplierBatches()
        },
        palletSearch: {
          idContains: this.overrideSelectedPallet()?.id,
        },
        fullDescriptionContains: debouncedFullDescriptionContains()
      })
    );

    this.filterInternalBatchSearch = computed(() => ({
        stockSearch: {
          ...this.actualStockSearch(),
          internalBatchSearch: {}
        },
      })
    );

    this.filterSupplierBatchSearch = computed(() => ({
        stockSearch: {
          ...this.actualStockSearch(),
          supplierBatchSearch: {}
        },
      })
    );

    const stockPageParams: Signal<[StockSearch, Pagination]> = computed(() => [this.actualStockSearch(), this.pagination()])

    this.stockPage = pipeSignal(stockPageParams, pipe(
        mergeMap(([stockSearch, pagination]) => this.#stockService.findStock(stockSearch, pagination)),
      )
    );

    this.bulkMovementTargetWarehouseSearch = signal({active: true});
    this.bulkMovementNeedsTargetWarehouse = computed(() => this.needsTargetWarehouse(this.bulkMovementTypeOverride()));

    this.bulkMovementValid = computed(() => {
      const isMovementTypeUndefined = this.bulkMovementTypeOverride() === undefined;
      const isTargetWarehouseUndefined = this.bulkMovementTargetWarehouse() === undefined;


      const isTargetWarehouseNotRequiredOrProvided = !this.bulkMovementNeedsTargetWarehouse() || !isTargetWarehouseUndefined;

      return !isMovementTypeUndefined &&
        isTargetWarehouseNotRequiredOrProvided
    });
    const bulkMovementTypeFromKindInput = derivedAsync(() => !this.bulkMovementKind() ? of(this.bulkMovementType()) : this.#movementTypeService.getMovementType$(this.bulkMovementKind()!));
    this.bulkMovementTypeOverride = linkedSignal(() => bulkMovementTypeFromKindInput())
  }

  loadStockList(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  typing(stock: Stock): Stock {
    return stock;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  openAddStockDialog(stock: Stock) {
    const movementType$ = this.#movementTypeService.getMovementType$('INVENTORY_PLUS');
    const currentUserCompany$ = this.#userService.getCurrentUserCompany$();
    const currentUser$ = this.#userService.getCurrentUser$();
    const movement: Partial<Movement> = {
      stock$: of(stock),
      stockPallet$: stock.pallet$,
      warehouse$: stock.warehouse$,
      movementType$: movementType$,
      date: new Date(),
      dateTime: new Date(),
      company$: currentUserCompany$,
      supplier$: stock.supplier$,
      deliverBeforeDate: stock.deliverBeforeDate,
      fullDescription: stock.fullDescription,
      location: stock.location,
      internalBatchName: stock.internalBatch,
      supplierBatchName: stock.supplierBatch,
      article$: stock.article$,
      user$: currentUser$,
      reception$: of(undefined),
      organization$: of(undefined),
      unitWeight: stock.unitWeight,
      unitsPerParcel: stock.unitsPerParcel,
      unitGrossWeight: stock.unitGrossWeight,
      timestamp: new Date(),
    }

    this.movementDialogRef = this.#dialogService.open(MovementDialogComponent, {
      header: 'Create movement',
      width: '40%',
      data: movement,
    });

    this.movementDialogRef.onClose.subscribe(_ => this.refresh(true));
  }

  needsTargetWarehouse(bulkMovementType?: MovementType) {
    return bulkMovementType?.movementKind === 'INTER_WAREHOUSE_TRANSFER';
  }

  private refresh(clearSelection: boolean) {
    if (clearSelection) {
      this.overrideSelectedStocks.set([]);
    }
    this.pagination.update(() => ({...this.pagination()}));
  }

  bulkMovementReady() {
    return this.bulkMovementValid() && this.overrideSelectedStocks()?.length > 0;
  }

  bulkMoveSelection() {
    if (!this.bulkMovementValid()) {
      return;
    }

    if (this.bulkMovementType()?.movementKind === 'INTER_WAREHOUSE_TRANSFER') {
      const interWarehouseTransfers: InterWarehouseTransfer[] = this.overrideSelectedStocks()
        .map(stock => ({
          stock$: of(stock),
          targetWarehouse$: of(this.bulkMovementTargetWarehouse()!),
          comment: this.bulkMovementComment(),
          stockPrevision$: of(undefined),
          movement$: of(undefined),
        }));

      const createdInterwarehouseTransfers: Observable<InterWarehouseTransfer>[] = interWarehouseTransfers
        .map(stockinterwarehouseTransfer => this.#interWarehouseTransferService.createInterWarehouseTransfer$(stockinterwarehouseTransfer));

      forkJoin(createdInterwarehouseTransfers).pipe(
        map(createdInterwarehouseTransfers => this.#notifyInterWarehouseTransfers(createdInterwarehouseTransfers)),
      ).subscribe(_ => this.refresh(true));
    } else {
      const stockAdjustments$ = this.overrideSelectedStocks()
        .map(stock => {
          const stockAdjustment = {
            quantity: stock.quantity,
            movementType$: of(this.bulkMovementTypeOverride()!),
            targetWarehouse$: of(this.bulkMovementTargetWarehouse()),
            supplier$: of(undefined),
            organization$: of(undefined),
          };

          return this.#stockAdjustmentService.createStockAdjustment(stock, stockAdjustment);
        });

      forkJoin(stockAdjustments$)
        .subscribe(stockAdjustments => {
          this.#notifyStockAdjustments(stockAdjustments);
          this.refresh(true);
        })
    }
  }

  notifyStockDragStart(dragEvent: DragEvent, stock: Stock) {
    let selectedStocks = this.#getStocksForDragAndDrop(stock);
    this.onStockDragStart.emit(selectedStocks);
  }

  notifyStockDragEnd(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.dropEffect !== 'none') {
      this.overrideSelectedStocks.set([]);
    }
    this.onStockDragEnd.emit();
  }

  #notifyInterWarehouseTransfers(interWarehouseTransfers: InterWarehouseTransfer[]) {
    this.#messageService.add({severity: 'success', summary: 'Transfer created', detail: `Created ${interWarehouseTransfers.length} bulk movements.`});
  }

  #notifyStockAdjustments(movements: Movement[]) {
    this.#messageService.add({severity: 'success', summary: 'Transfer created', detail: `Created ${movements.length} bulk movements.`});
  }

  #getStocksForDragAndDrop(stock: Stock) {
    let selectedStocks = this.overrideSelectedStocks();
    if (selectedStocks.indexOf(stock) < 0) {
      selectedStocks = [...selectedStocks, stock];
    }
    return selectedStocks;
  }
}

import {computed, CreateComputedOptions, effect, signal, Signal, WritableSignal} from "@angular/core";
import {Observable, OperatorFunction, switchAll, tap} from "rxjs";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";

export function overrideSignal<T>(source: Signal<T>): WritableSignal<T> {
  const override: WritableSignal<T> = signal(source());
  computed(() => {
    console.log('source override', source());
    override.set(source());
  }, {
  });
  return override;
}

export function pipeSignal<T, U>(signal: Signal<T>, pipe: OperatorFunction<T, U>): Signal<U | undefined> {
  const signalAsObservable = toObservable(signal);
  const signalPipe = signalAsObservable.pipe(pipe);

  return toSignal(signalPipe);
}

export function pipeSignalWithDefault<T, U>(signal: Signal<T>, pipe: OperatorFunction<T, U>, initialValue: U): Signal<U> {
  const signalAsObservable = toObservable(signal);
  const signalPipe = signalAsObservable.pipe(pipe);

  return toSignal(signalPipe, {initialValue});
}

export function computedFromObservable<T>(computation: () => Observable<T>, options?: CreateComputedOptions<Observable<T>>): Signal<T | undefined> {
  const identityType: OperatorFunction<T, T> = source => source;
  return computedPipelineFromObservable(computation, identityType, undefined, options);
}

export function computedPipelineFromObservable<T, U>(computation: () => Observable<T>, pipe: OperatorFunction<T, U>, initialValue: U, options?: CreateComputedOptions<Observable<T>>): Signal<U> {
  const computedSignal = computed(computation, options);
  const computedObservable = toObservable(computedSignal);
  const observable = computedObservable.pipe(switchAll())
    .pipe(pipe);
  return toSignal(observable, {initialValue: initialValue});
}

export function loadSignalFromObservable<T>(computation: () => Observable<T>, loading?: WritableSignal<Boolean>, options?: CreateComputedOptions<Observable<T>>): Signal<T | undefined> {
  const computedSignal = computed(computation, options);
  const computedObservable = toObservable(computedSignal);

  const observable = computedObservable.pipe(
    tap(() => loading?.set(true)),
    switchAll()
  ).pipe(
    tap(() => loading?.set(false)),
  );

  return toSignal(observable);
}

import {Component, computed, inject, Signal, signal, WritableSignal} from '@angular/core';
import {pipe, switchMap} from "rxjs";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import {Page} from '@typedefs/page';
import {SupplierSearch} from '@model/search/supplier-search';
import {Supplier} from '@model/supplier';
import {DialogService} from 'primeng/dynamicdialog';
import {SupplierService} from '@services/supplier.service';
import {SupplierCategory} from '@model/supplier-category';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {UserService} from '@services/user.service';
import {Pagination} from "@services/pagination";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {toSignal} from "@angular/core/rxjs-interop";
import {SupplierCategorySearch} from "@model/search/supplier-category-search";
import {joinWhenPresent} from "@util/string-utils";
import { PrimeTemplate } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { SuppliersSelectionComponent } from './selection/multiple/suppliers-selection.component';
import { FormsModule } from '@angular/forms';
import { SupplierCategorySelectionComponent } from '../supplier-category/supplier-category-selection.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { SupplierComponent } from './supplier.component';
import { SupplierCategoryComponent } from '../supplier-category/supplier-category.component';
import { PhoneNumberPipe } from '@util/phone-number-pipe';

@Component({
    selector: 'foodbank-suppliers',
    templateUrl: './supplier-list.component.html',
    styleUrls: ['./supplier-list.component.scss'],
    providers: [DialogService],
    imports: [TableModule, PrimeTemplate, InputTextModule, SuppliersSelectionComponent, FormsModule, SupplierCategorySelectionComponent, NgIf, SupplierComponent, SupplierCategoryComponent, AsyncPipe, PhoneNumberPipe]
})
export class SupplierListComponent {

  supplierPage: Signal<Page<Supplier> | undefined>;

  supplierSearch: Signal<SupplierSearch>;

  supplierFilterSearch: Signal<SupplierSearch>;
  supplierCategoryFilterSearch: Signal<SupplierCategorySearch>;
  selectedSuppliers = signal<Supplier[]>([]);
  selectedSupplierCategories = signal<SupplierCategory[]>([]);

  pagination: WritableSignal<Pagination>;

  private supplierService = inject(SupplierService);
  private paginationService = inject(PaginationService);
  private userService = inject(UserService);

  constructor() {
    const currentUserCompany$ = this.userService.getCurrentUserCompany$();
    const currentUserCompany = toSignal(currentUserCompany$);

    this.supplierFilterSearch = computed(() => ({
        company: currentUserCompany(),
      })
    );
    this.supplierCategoryFilterSearch = signal({});

    this.supplierSearch = computed(() => ({
        includeESFPlus: false,
        includeCountrySuppliers: true,
        suppliers: this.selectedSuppliers(),
        supplierCategorySearch: {
          supplierCategoryList: this.selectedSupplierCategories()
        },
        company: currentUserCompany(),
      })
    );

    this.pagination = this.paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    const supplierPageParams: Signal<[SupplierSearch, Pagination]> = computed(() => [this.supplierSearch(), this.pagination()]);

    this.supplierPage = pipeSignal(supplierPageParams, pipe(
        switchMap(([supplierSearch, pagination]) => this.supplierService.findSuppliers$(supplierSearch, pagination))
      )
    );
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(supplier: Supplier): Supplier {
    return supplier;
  }

  getAddress(supplier: Supplier) {
    const location = joinWhenPresent(" ", [supplier.zip, supplier.city]);
    return joinWhenPresent(" ,", [supplier.address, supplier.address2, location]);
  }
}

<div class="flex align-items-center">
  <div class="flex m-2">
    <span class="font-semibold" i18n>Selection mode:</span>
  </div>
  @for (dateSelectionMode of dateSelectionModes; track dateSelectionMode) {
    <div class="flex m-2 align-items-center">
      <p-radioButton
        [inputId]="dateSelectionMode"
        name="dateSelectionMode"
        [value]="dateSelectionMode"
        [(ngModel)]="ngModel"/>
      <label class="ml-1" [for]="dateSelectionMode" i18n>
        {{ dateSelectionMode }}
      </label>
    </div>
  }
</div>

import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BehaviorSubject, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap} from 'rxjs';
import {OrganizationSearch} from '@model/search/organization-search';
import {OrganizationService} from '@services/organization.service';
import {Organization} from '@model/organization';
import {ComponentChanges} from "@util/component-change";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {AsyncPipe} from "@angular/common";
import {OrganizationComponent} from "@components/organization/organization.component";

@Component({
    selector: 'foodbank-organization-selection',
    templateUrl: './organization-single-selection.component.html',
    styleUrls: ['./organization-single-selection.component.scss'],
    imports: [
        DropdownModule,
        FormsModule,
        AsyncPipe,
        OrganizationComponent
    ]
})
export class OrganizationSingleSelectionComponent implements OnInit, OnChanges {

  @Input()
  ngModel: Organization | undefined;
  @Input()
  organizationSearch: OrganizationSearch | undefined; // you could for example create a organization filter to get organizations of a specific user
  organizationSearch$!: BehaviorSubject<OrganizationSearch>;

  @Output()
  ngModelChange: EventEmitter<Organization> = new EventEmitter<Organization>();

  organizations$: Observable<Organization[]> | undefined;

  loading = true;

  constructor(private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.organizationSearch$ = new BehaviorSubject<OrganizationSearch>(this.organizationSearch || {});

    this.organizations$ = this.organizationSearch$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      switchMap(organizationSearch =>
        this.organizationService.findOrganizations$((organizationSearch), {page: 0, size: 1000})),
      tap(() => this.loading = false),
      map(page => page.content),
      shareReplay()
    );
  }

  ngOnChanges(changes: ComponentChanges<OrganizationSingleSelectionComponent>): void {
    if (changes.organizationSearch && this.organizationSearch$) {
      this.organizationSearch$.next(this.organizationSearch || {})
    }
  }

  select(selectedOrganization: Organization | undefined) {
    this.ngModel = selectedOrganization;
    this.ngModelChange.emit(selectedOrganization);
  }

  clear() {
    this.select(undefined);
  }
}

import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, debounceTime, filter, forkJoin, map, mergeMap, Observable, shareReplay, switchMap} from "rxjs";
import {PreparationDistributionLine} from "@model/preparation-distribution-line";
import {Organization} from "@model/organization";
import {Preparation} from "@model/preparation";
import {ComponentChanges} from "@util/component-change";
import {PreparationDistributionService} from "@services/preparation-distribution.service";
import {Page} from "@typedefs/page";
import {PreparationDistributionSummary} from "@model/preparation-distribution-summary";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import {Pagination} from "@services/pagination";
import {PaginationService} from "@services/pagination.service";
import { PrimeTemplate } from 'primeng/api';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { OrganizationComponent } from '../../organization/organization.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ChartModule } from 'primeng/chart';

type PreparationDistributionLineRow = PreparationDistributionLine & { rowId: string }
type PreparationDistributionLineWithOrganization = PreparationDistributionLine & { organization: Organization }

@Component({
    selector: 'foodbank-preparation-distribution',
    templateUrl: './preparation-distribution.component.html',
    styleUrl: './preparation-distribution.component.scss',
    imports: [TableModule, PrimeTemplate, NgIf, OrganizationComponent, FormsModule, InputTextModule, ChartModule, AsyncPipe, DecimalPipe]
})
export class PreparationDistributionComponent implements OnInit, OnChanges {

  protected readonly DEFAULT_ROWS_PER_PAGE = 15;

  @Input() preparation: Preparation | undefined;
  @Input() tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';
  @Output() onDistributionListChange = new EventEmitter<PreparationDistributionLine | undefined>();

  preparation$: BehaviorSubject<Preparation | undefined>;
  pagination$: BehaviorSubject<Pagination>;
  distributionPage$: Observable<Page<PreparationDistributionLineRow> | undefined>;

  refreshTrigger$ = new BehaviorSubject<void>(undefined);
  distributionSummary$: Observable<PreparationDistributionSummary | undefined>;
  totalBeneficiaries$: Observable<number | undefined>;
  totalBeneficiariesOverride$: Observable<number | undefined>;
  chartData$: Observable<any>;

  savePreparationDistributionLineSink$: BehaviorSubject<PreparationDistributionLine | undefined> = new BehaviorSubject<PreparationDistributionLine | undefined>(undefined);

  private paginationService = inject(PaginationService);
  private preparationDistributionService = inject(PreparationDistributionService);

  constructor() {
    const defaultPagination = this.paginationService.getDefaultPagination(15);
    this.pagination$ = new BehaviorSubject<Pagination>(defaultPagination);
    this.preparation$ = new BehaviorSubject<Preparation | undefined>(undefined)
    this.distributionPage$ = new BehaviorSubject<Page<PreparationDistributionLineRow> | undefined>(undefined);

    const preparation$ = this.preparation$.pipe(
      debounceTime(100), // There are multiple changes coming from upstream on initialization
      filter(preparation => !!preparation && !!preparation.id),
      shareReplay()
    );

    this.distributionSummary$ = combineLatest([preparation$, this.refreshTrigger$]).pipe(
      mergeMap(([preparation, _]) => this.preparationDistributionService.getDistributionSummary$(preparation!)),
      shareReplay()
    )

    this.distributionPage$ = combineLatest([preparation$, this.pagination$]).pipe(
      debounceTime(100),
      mergeMap(([preparation, pagination]) => this.preparationDistributionService.getDistribution$(preparation!, pagination)),
      mergeMap(distributionList => {
        const rowsPage$: Observable<PreparationDistributionLineRow>[] = distributionList.content
          .map(distribution => this.mapToPreparationDistributionLineRow$(distribution))

        return forkJoin(rowsPage$).pipe(
          map(content => ({...distributionList, content} as Page<PreparationDistributionLineRow>))
        );
      }),
      shareReplay()
    );
    this.chartData$ = this.createChartData();

    this.totalBeneficiaries$ = this.distributionSummary$.pipe(map(summary => summary?.totalBeneficiaries));
    this.totalBeneficiariesOverride$ = this.distributionSummary$.pipe(map(summary => summary?.totalBeneficiariesOverride));
    this.savePreparationDistributionLineSink$.pipe(
      filter(preparationDistributionLine => this.preparationDistributionLineIsValid(preparationDistributionLine)),
      debounceTime(300),
      switchMap(preparationDistributionLine => this.preparationDistributionService.save$(preparationDistributionLine!))
    )
      .subscribe(line => {
        this.refreshTrigger$.next(undefined);
        this.onDistributionListChange.emit(line);
      })
  }

  private createChartData() {
    const preparationDistributionLineWithOrganizationList$: Observable<PreparationDistributionLineWithOrganization[]> = this.distributionPage$.pipe(
      map(distributionPage => distributionPage?.content),
      mergeMap(preparationDistributionLineRows => {
        const preparationDistributionLineRowWithObservableOrganization: [PreparationDistributionLineRow, Observable<Organization>][] = preparationDistributionLineRows?.map(preparationDistributionLineRow => [preparationDistributionLineRow, preparationDistributionLineRow.organization$]) || [];
        const preparationDistributionLineWithOrganization$: Observable<PreparationDistributionLineWithOrganization>[] = preparationDistributionLineRowWithObservableOrganization.map(([preparationDistributionLineRow, organization$]) => organization$.pipe(map(organization => ({
          ...preparationDistributionLineRow,
          organization: organization,
        }))));
        return combineLatest(preparationDistributionLineWithOrganization$);
      })
    );
    return preparationDistributionLineWithOrganizationList$.pipe(
      map((preparationDistributionLineWithOrganizations: PreparationDistributionLineWithOrganization[]) => ({
          labels: preparationDistributionLineWithOrganizations.map(preparationDistributionLineWithOrganization => preparationDistributionLineWithOrganization.organization.name),
          datasets: [
            {
              data: preparationDistributionLineWithOrganizations.map(preparationDistributionLineWithOrganization => preparationDistributionLineWithOrganization.beneficiaries) || [],
            }
          ]
        })
      )
    );
  }

  private mapToPreparationDistributionLineRow$<T>(distribution: PreparationDistributionLine): Observable<PreparationDistributionLineRow> {
    return forkJoin([distribution.preparation$, distribution.organization$])
      .pipe(map(([preparation, organization]) => {
        return {
          ...distribution,
          rowId: `${preparation.id}-${organization.id}`
        }
      }))
  }

  ngOnInit() {
    this.preparation$.next(this.preparation);
  }

  ngOnChanges(changes: ComponentChanges<PreparationDistributionComponent>) {
    if (!changes.preparation || !changes.preparation.currentValue) {
      return;
    }

    const preparation = changes.preparation.currentValue;
    this.preparation$.next(preparation);
  }

  organizationIdentity(organization: any): Organization {
    return organization as Organization;
  }

  distributionIdentity(distribution: any): PreparationDistributionLine {
    return distribution as PreparationDistributionLine;
  }

  save(preparationDistributionLine: PreparationDistributionLine) {
    this.savePreparationDistributionLineSink$.next(preparationDistributionLine);
  }

  lazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination$.next(pagination);
  }

  private preparationDistributionLineIsValid(preparationDistributionLine: PreparationDistributionLine | undefined): boolean {
    console.log(preparationDistributionLine)
    return preparationDistributionLine !== undefined &&
      Number.isInteger(preparationDistributionLine.beneficiaries) &&
      Number.isInteger(preparationDistributionLine.beneficiariesOverride);
  }
}

import {Component, model} from '@angular/core';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FormsModule} from "@angular/forms";
import {DateSelectionMode} from "@services/date-selection.mode";

@Component({
  selector: 'foodbank-date-selection-mode',
  templateUrl: './date-selection-mode.component.html',
  imports: [
    RadioButtonModule,
    FormsModule
  ]
})
export class DateSelectionModeComponent {

  ngModel = model<DateSelectionMode>(DateSelectionMode.Single);
  dateSelectionModes = [DateSelectionMode.Single, DateSelectionMode.Range];

}

import {Injectable} from '@angular/core';
import {DateSearchDto} from '@typedefs/stock-rest';
import {copyCommonFields} from "@model/mapping-utils";
import {DateSearch} from "@model/search/date-search";
import {DateSelectionMode} from "@services/date-selection.mode";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  mapToDateSearchDto(dateSearch: DateSearch): DateSearchDto {
    const commonFields: DateSearchDto | DateSearch = copyCommonFields(dateSearch, []);
    return {
      ...commonFields,
    }
  }

  saveDateSelectionMode(inputId: string, dateSelectionMode: DateSelectionMode) {
    const key = this.#getSelectionModePrefKey(inputId);

    localStorage.setItem(key, dateSelectionMode);
  }

  loadDateSelectionMode(inputId: string) {
    const key = this.#getSelectionModePrefKey(inputId);

    return localStorage.getItem(key) as DateSelectionMode | undefined;
  }

  convertToUtcDate(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  #getSelectionModePrefKey(inputId: string) {
    return inputId + '.selection.mode';
  }

  addDays(sourceDate: Date, offset: number) {
    const dateAsNumber = sourceDate.getDate();
    const nextDayDateAsNumber = sourceDate.setDate(dateAsNumber + offset);
    const date = new Date(nextDayDateAsNumber);
    return this.convertToUtcDate(date);
  }

}

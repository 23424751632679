import {Injector, ResourceRef} from "@angular/core";
import {Cache} from "@util/cache";
import {rxResource} from "@angular/core/rxjs-interop";
import {Observable, of} from "rxjs";

export class CachedResource<K, V> {

  cache: Cache<K, ResourceRef<V>> = new Cache<K, ResourceRef<V>>();

  constructor(
    private injector: Injector,
    private loader: (id: K) => Observable<V>) {
  }

  get(id: K): ResourceRef<V> {
    return this.cache.get(id, () => rxResource({
      loader: () => this.loader(id),
      injector: this.injector,
    }));
  }

  getIfDefined(id: K | undefined): ResourceRef<V | undefined> {
    if (!id) {
      return rxResource({
        loader: () => of(undefined),
        injector: this.injector,
      });
    }
    return this.get(id);
  }

}

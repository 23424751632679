import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {Article} from '@model/article';
import {ArticleSearch} from '@model/search/article-search';
import {ArticleDto, ArticleSearchDto, ArticleWithStockSummaryDto, ArticleWithStockSummarySearchDto} from '@typedefs/stock-rest';
import {ArticleCategoryService} from './article-category.service';
import {ArticleStorageConditionService} from './article-storage-condition.service';
import {StockService} from '@services/stock.service';
import {StockPrevisionService} from '@services/stock-prevision.service';
import {TransferRequestService} from "@services/transfer-request.service";
import {StockGroupService} from "@services/stock-group.service";
import {copyCommonFields} from "@model/mapping-utils";
import {TransferRequestItemService} from "@services/transfer-request-item.service";
import {FoodbankCache} from "@services/foodabank-cache";
import {ArticleWithStockSummary} from "@model/article-with-stock-summary";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {StockSearch} from "@model/search/stock-search";

type ArticlePagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  #httpClient = inject(HttpClient);
  #articleCategoryService = inject(ArticleCategoryService);
  #articleStorageConditionService = inject(ArticleStorageConditionService);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  getArticle$(id: string): Observable<Article> {
    return this.#httpClient.get<ArticleDto>(`${environment.apiUrl}/articles/${id}`)
      .pipe(
        map(articleDto => this.mapToArticle(articleDto)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  findArticles$(articleSearch: ArticleSearch, pagination?: ArticlePagination): Observable<Page<Article>> {
    const articleSearchDto = this.mapToArticleSearchDto(articleSearch);
    return this.#httpClient.post<Page<ArticleDto>>(`${environment.apiUrl}/articles/search`, articleSearchDto, {params: pagination})
      .pipe(map(articlePage => this.#loadArticlePage(articlePage)));
  }

  findArticleWithSummaryPage$(articleSearch: ArticleSearch, stockSearch: StockSearch, pagination: Pagination, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<ArticleWithStockSummary>> {
    const articleSearchDto = this.mapToArticleSearchDto(articleSearch);

    const stockService = this.#injector.get(StockService);
    const stockSearchDto = stockService.mapToStockSearchDto(stockSearch);

    const articleWithStockSummarySearchDto: ArticleWithStockSummarySearchDto = { articleSearchDto, stockSearchDto};

    return this.#httpClient.post<Page<ArticleWithStockSummaryDto>>(`${environment.apiUrl}/articles/summary/search`, articleWithStockSummarySearchDto, {params: pagination})
      .pipe(map(articleDtoPage => {
        const articleWithStockSummaryDtos: ArticleWithStockSummary[] = articleDtoPage.content.map(articleWithStockSummaryDto => this.mapToArticleWithStockSummary(articleWithStockSummaryDto, cache));
        return {
          ...articleDtoPage,
          content: articleWithStockSummaryDtos
        }
      }));
  }

  updateArticle(article: ArticleDto): Observable<ArticleDto> {
    return this.#httpClient.put<ArticleDto>(`${environment.apiUrl}/articles/${article.id}`, article)
  }

  mapToArticle(articleDto: ArticleDto): Article {
    return {
      ...articleDto,
      articleCategory$: this.#articleCategoryService.findArticleCategory$(articleDto.articleCategoryId).pipe(shareReplay(1)),
      articleStorageCondition$: this.#articleStorageConditionService.findArticleStorageCondition$(articleDto.articleStorageConditionId).pipe(shareReplay(1))
    };
  }

  mapToArticleSearchDto(articleSearch: ArticleSearch): ArticleSearchDto {
    const stockService = this.#injector.get(StockService);
    const stockPrevisionService = this.#injector.get(StockPrevisionService);
    const transferRequestService = this.#injector.get(TransferRequestService);
    const transferRequestItemService = this.#injector.get(TransferRequestItemService);
    const stockGroupService = this.#injector.get(StockGroupService);

    const commonFields: ArticleSearchDto | ArticleSearch = copyCommonFields(articleSearch, ['articles', 'articleCategorySearch', 'articleStorageConditionSearch', 'preparation', 'stockSearch', 'stockPrevisionSearch', 'transferRequestSearch', 'transferRequestItemSearch', 'stockGroupSearch']);

    return {
      ...commonFields,
      articleIds: articleSearch.articles?.map(article => article.id),
      articleCategorySearchDto: articleSearch.articleCategorySearch ? this.#articleCategoryService.mapToArticleCategorySearchDto(articleSearch.articleCategorySearch) : undefined,
      articleStorageConditionSearchDto: articleSearch.articleStorageConditionSearch && this.#articleStorageConditionService.mapToArticleStorageConditionSearchDto(articleSearch.articleStorageConditionSearch),
      preparationId: articleSearch.preparation?.id,
      stockSearchDto: articleSearch.stockSearch && stockService.mapToStockSearchDto(articleSearch.stockSearch),
      stockPrevisionSearchDto: articleSearch.stockPrevisionSearch ? stockPrevisionService.mapToStockPrevisionSearchDto(articleSearch.stockPrevisionSearch) : undefined,
      transferRequestSearchDto: articleSearch.transferRequestSearch ? transferRequestService.mapToTransferRequestSearchDto(articleSearch.transferRequestSearch) : undefined,
      transferRequestItemSearchDto: articleSearch.transferRequestItemSearch ? transferRequestItemService.mapToTransferRequestItemSearchDto(articleSearch.transferRequestItemSearch) : undefined,
      stockGroupSearchDto: articleSearch.stockGroupSearch ? stockGroupService.mapToStockGroupSearchDto(articleSearch.stockGroupSearch) : undefined,
      ...(articleSearch.bulkFood !== undefined ? {bulkFood: articleSearch.bulkFood} : {}) // FIXME isn't this overkill?
    }
  }

  mapToArticleWithStockSummary(articleWithStockSummaryDto: ArticleWithStockSummaryDto, cache: FoodbankCache): ArticleWithStockSummary {
    const stockService = this.#injector.get(StockService);

    const commonFields: ArticleWithStockSummary | ArticleWithStockSummaryDto = copyCommonFields(articleWithStockSummaryDto, ['articleDto', 'stockSummaryDto']);
    return {
      ...commonFields,
      article: this.mapToArticle(articleWithStockSummaryDto.articleDto),
      stockSummary: stockService.mapToStockSummary(articleWithStockSummaryDto.stockSummaryDto),
    };
  }

  // TODO: pass article!!!
  #loadArticlePage(articleDtoPage: Page<ArticleDto>): Page<Article> {
    return {
      ...articleDtoPage,
      content: this.#loadArticleDetailsList(articleDtoPage.content)
    };
  }

  #loadArticleDetailsList(articleDtos: ArticleDto[]) {
    return articleDtos.map(article => this.mapToArticle(article));
  }

}

import { combineLatest, EMPTY, map, Observable, of, shareReplay, switchMap, take, throwError } from "rxjs";
import { Reception } from "@model/reception";
import { ReceptionItemDto, ReceptionItemSummaryDto } from "@typedefs/stock-rest";
import { environment } from "@environments/environment";
import { inject, Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ReceptionItem } from "@model/reception-item";
import { Page } from "@typedefs/page";
import { ReceptionPagination, ReceptionService } from "@services/reception.service";
import { ReceptionItemSearch } from "@model/search/reception-item-search";
import { Article } from "@model/article";
import { Returnable } from "@model/returnable";
import { ArticleService } from "@services/article.service";
import { ReturnableService } from "@services/returnable.service";
import { ReceptionItemSummary } from "@model/reception-item-summary";
import { rxResource } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class ReceptionItemService {

  private httpClient = inject(HttpClient);
  private receptionService = inject(ReceptionService);
  private articleService = inject(ArticleService);
  private returnableService = inject(ReturnableService);

  getReceptionItem$(receptionId: number, rank: number, injector: Injector): Observable<ReceptionItem> {
    return this.httpClient.get<ReceptionItemDto>(`${environment.apiUrl}/receptions/${receptionId}/items/${rank}`)
      .pipe(
        map(receptionItem => this.mapToReceptionItem(receptionItem, injector)),
        shareReplay()
      );
  }

  getReceptionItems$(receptionItemSearch: ReceptionItemSearch, pagination: ReceptionPagination, injector: Injector): Observable<Page<ReceptionItem>> {
    const reception = receptionItemSearch.reception;
    return this.findReceptionItems(reception, pagination, injector);
  }

  findReceptionItems(reception: Reception, pagination: ReceptionPagination, injector: Injector) {
    return this.httpClient.get<Page<ReceptionItemDto>>(`${environment.apiUrl}/receptions/${reception.id}/items/search`, {params: pagination})
      .pipe(map(receptionPage => {
        return {
          ...receptionPage,
          content: receptionPage.content.map(receptionItem => this.mapToReceptionItem(receptionItem, injector))
        }
      }));
  }

  deleteReceptionItem$(receptionItem: ReceptionItem): Observable<any> {
    return receptionItem.reception$.pipe(
      switchMap(reception => this.httpClient.delete(`${environment.apiUrl}/receptions/${reception.id}/items/${receptionItem.rank}`))
    )
  }

  getSummary$(receptionItem: ReceptionItemDto): Observable<ReceptionItemSummary> {
    const summary$ = receptionItem.receptionId && receptionItem.rank
      ? this.httpClient.get<ReceptionItemSummaryDto>(`${environment.apiUrl}/receptions/${receptionItem.receptionId}/items/${receptionItem.rank}/summary`)
      : EMPTY

    return summary$.pipe(
      map(receptionItemSummaryDto => this.mapToReceptionItemSummary(receptionItemSummaryDto)),
      shareReplay(),
    )
  }

  saveReceptionItem$(receptionItem: ReceptionItem, injector: Injector): Observable<ReceptionItem> {
    return this.saveReceptionItem(receptionItem, injector).pipe(
      map(savedReceptionItem => this.mapToReceptionItem(savedReceptionItem, injector))
    );
  }

  saveReceptionItemDeprecated$(receptionItem: ReceptionItem, injector: Injector): Observable<ReceptionItem> {
    return combineLatest([receptionItem.reception$.pipe(take(1))]).pipe(
      switchMap(([reception]) => this.saveReceptionItemDeprecated(reception, receptionItem)),
      map(savedReceptionItem => this.mapToReceptionItem(savedReceptionItem, injector))
    )
  }

  isBulkFood(article: Article | undefined) {
    return article?.bulkFood ?? false;
  }

  private saveReceptionItem(receptionItem: ReceptionItem, injector: Injector) {
    const reception = receptionItem.reception.value();

    if (!reception) {
      return throwError(() => new Error('Validation Error: Reception must be provided.'));
    }

    const article = receptionItem.article.value();
    const returnable = receptionItem.returnable.value();

    const receptionItemDto = this.mapToReceptionItemDto(receptionItem, reception, article, returnable);
    return this.httpClient.post<ReceptionItemDto>(`${environment.apiUrl}/receptions/${reception.id}/items`, receptionItemDto).pipe(
      shareReplay()
    );
  }

  private saveReceptionItemDeprecated(reception: Reception, receptionItem: ReceptionItem) {
    const receptionItemDto$ = this.mapToReceptionItemDto$(receptionItem);
    return receptionItemDto$.pipe(
      switchMap(receptionItemDto => this.httpClient.post<ReceptionItemDto>(`${environment.apiUrl}/receptions/${reception.id}/items`, receptionItemDto)),
      shareReplay(),
    );
  }

  mapToReceptionItem(receptionItemDto: ReceptionItemDto, injector: Injector): ReceptionItem {
    return {
      receptionId: receptionItemDto.receptionId,
      rank: receptionItemDto.rank,
      supplierLot: receptionItemDto.supplierLot,
      internalLot: receptionItemDto.internalLot,
      palletQuantity: receptionItemDto.palletQuantity,
      quantity: receptionItemDto.quantity,
      unitWeight: receptionItemDto.unitWeight,
      description: receptionItemDto.description,
      unitsPerParcel: receptionItemDto.unitsPerParcel,
      unitGrossWeight: receptionItemDto.unitGrossWeight,
      expirationDate: receptionItemDto.expirationDate,
      bestBeforeDate: receptionItemDto.bestBeforeDate,

      reception$: this.loadReception$(receptionItemDto),
      reception: rxResource({loader: () => this.loadReception$(receptionItemDto), injector}),
      article$: this.loadArticle$(receptionItemDto),
      article: rxResource({loader: () => this.loadArticle$(receptionItemDto), injector}),
      returnable$: this.loadReturnable$(receptionItemDto),
      returnable: rxResource({loader: () => this.loadReturnable$(receptionItemDto), injector}),
      summary$: this.getSummary$(receptionItemDto),
      summary: rxResource({loader: () => this.getSummary$(receptionItemDto), injector})
    };
  }

  mapToReceptionItemDto$(receptionItem: ReceptionItem): Observable<ReceptionItemDto> {
    return combineLatest([receptionItem.reception$, receptionItem.article$, receptionItem.returnable$]).pipe(
      map(([reception, article, returnable]) => this.mapToReceptionItemDto(receptionItem, reception, article, returnable))
    );
  }

  private mapToReceptionItemDto(receptionItem: ReceptionItem, reception: Reception, article?: Article, returnable?: Returnable): ReceptionItemDto {
    return {
      rank: receptionItem.rank,
      receptionId: reception.id,
      articleId: article?.id,
      supplierLot: receptionItem.supplierLot,
      internalLot: receptionItem.internalLot,
      palletQuantity: receptionItem.palletQuantity,
      unitWeight: receptionItem.unitWeight,
      description: receptionItem.description,
      unitsPerParcel: receptionItem.unitsPerParcel,
      unitGrossWeight: receptionItem.unitGrossWeight,
      returnableId: returnable?.id,
      expirationDate: receptionItem.expirationDate,
      bestBeforeDate: receptionItem.bestBeforeDate,
      quantity: receptionItem.quantity
    };
  }

  private loadReception$(receptionItemDto: ReceptionItemDto) {
    const receptionId = receptionItemDto.receptionId;
    return this.receptionService.getReception$(receptionId);
  }

  private loadArticle$(reception: ReceptionItemDto): Observable<Article | undefined> {
    const articleId = reception.articleId;
    return articleId ? this.articleService.getArticle$(articleId) : of(undefined);
  }

  private loadReturnable$(receptionItemDto: ReceptionItemDto): Observable<Returnable | undefined> {
    const returnableId = receptionItemDto.returnableId;
    return returnableId ? this.returnableService.getReturnable$(returnableId) : of(undefined);
  }

  private mapToReceptionItemSummary(receptionItemSummaryDto: ReceptionItemSummaryDto): ReceptionItemSummary {
    return {
      showParcelsPerPallet: receptionItemSummaryDto.showParcelsPerPallet,
      netWeight: receptionItemSummaryDto.netWeight,
      grossWeight: receptionItemSummaryDto.grossWeight,
      palletCount: receptionItemSummaryDto.palletCount,
      parcelCount: receptionItemSummaryDto.parcelCount,
      unitCount: receptionItemSummaryDto.unitCount,
      receptionItemCount: receptionItemSummaryDto.receptionItemCount
    }
  }
}

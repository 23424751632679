import {Component, Input} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Stock} from '@model/stock';
import { ButtonDirective } from 'primeng/button';
import { Ripple } from 'primeng/ripple';
import { NgIf, NgFor } from '@angular/common';
import { NgxPrintDirective } from 'ngx-print';
import { PalletLabelComponent } from '../../pallet/pallet-label/pallet-label.component';

@Component({
    selector: 'foodbank-pallet-labels',
    templateUrl: './pallet-labels.component.html',
    styleUrl: './pallet-labels.component.scss',
    imports: [ButtonDirective, Ripple, NgIf, NgxPrintDirective, NgFor, PalletLabelComponent]
})
export class PalletLabelsComponent {

  @Input()
  stocks?: Stock[];

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<Stock[]>) {
    this.stocks = this.config.data as Stock[];
  }

  close() {
    this.ref.close({});
  }
}

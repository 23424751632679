import {Component, Input} from '@angular/core';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'foodbank-user-type',
    templateUrl: './user-type.component.html',
    styleUrls: ['./user-type.component.scss'],
    imports: [NgSwitch, TooltipModule, NgSwitchCase, NgSwitchDefault]
})
export class UserTypeComponent {

  @Input() userType: string | undefined;

}

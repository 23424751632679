import {Component, Input} from '@angular/core';
import {Location} from "@model/location";

@Component({
    selector: 'foodbank-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent {
  @Input()
  location?: Location;
}

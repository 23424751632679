<div #tableContainer>
  <ng-container *ngIf="stockPage$ | async">
    <p-table #dt
             [value]="(((stockPage$ | async)?.content) || [])"
             responsiveLayout="scroll"
             [rows]="DEFAULT_ROWS_PER_PAGE"
             [totalRecords]="((stockPage$ | async)?.totalElements || 0)"
             [globalFilterFields]="['depot']"
             [paginator]="true"
             [rowsPerPageOptions]="[15,25,50,100]"
             [showCurrentPageReport]="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             [lazy]="true"
             (onLazyLoad)="loadStockList($event)"
             [rowHover]="true"
             [resizableColumns]="true"
             columnResizeMode="expand"
             sortMode="multiple"
             selectionMode="multiple"
             [selection]="selectedStocks"
             (selectionChange)="notifySelectionChange($event)"
             dataKey="id"
             [multiSortMeta]="[{ field: 'BEST_BEFORE_DATE', order: -1 }, { field: 'ARTICLE_ID', order: 1 }]"
             editMode="cell"
             [styleClass]="tableSizeStyleClass"
             [scrollable]="true"
             [scrollHeight]="tableHeight"
    >

      <ng-template pTemplate="caption">
        <div class="flex flex-wrap mb-0 justify-content-between">
          <div>
            <h3 class="m-2" i18n>Stock</h3>
          </div>
          <div class="flex flex-row mb-1">
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr style="vertical-align: bottom">
          <th *ngIf="selectionEnabled$ | async">
            <p-tableHeaderCheckbox  />
          </th>
          <th>#</th>
          <th pSortableColumn="ARTICLE_STORAGE_CONDITION_ID" pResizableColumn>
            <span i18n>Storage</span>
            <p-sortIcon field="ARTICLE_STORAGE_CONDITION_ID" />
          </th>
          <th pSortableColumn="ARTICLE_ID" pResizableColumn>
            <span i18n>Article</span>
            <p-sortIcon field="ARTICLE_ID" />
          </th>
          <th>
            <span i18n>Description</span>
          </th>
          <th>
            <span i18n>Internal batch</span>
          </th>
          <th>
            <span i18n>Net Weight (Kg)</span>
          </th>
          <th>
            <span i18n>Units</span>
          </th>
          <th pSortableColumn="BEST_BEFORE_DATE">
            <span i18n>Best before date</span>
            <p-sortIcon field="BEST_BEFORE_DATE" />
          </th>
          <th>
            <span i18n>Deliver before date</span>
          </th>
          <th>
            <span i18n>Location</span>
          </th>
          <th>
            <span i18n pSortableColumn="PALLET_ID">Pallet id</span>
          </th>
          <th>
            <span i18n>Net unit weight (gr)</span>
          </th>
          <th>
            <span i18n>Units per parcel</span>
          </th>
        </tr>
        <tr>
          <th *ngIf="selectionEnabled$ | async"></th>
          <th></th>
          <th>
            <p-columnFilter field="storageConditions" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <foodbank-article-storage-condition-selection [selectedArticleStorageConditions]="value"
                  [articleStorageConditionSearch]="(filterArticleStorageConditionSearch$ | async) || {}"
                  (onArticleStorageConditionSelected)="filter($event)"
                 />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <foodbank-article-multi-selection [selectedArticles]="value"
                  [articleSearch]="(filterArticleSearch$ | async) || {}"
                  (onArticleSelected)="filter($event)"
                 />
              </ng-template>
            </p-columnFilter>
          </th>
          <th colspan="4"></th>
          <th>
            <p-columnFilter field="bestBeforeDate" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <foodbank-date-picker id="best-before-date" name="best-before-date"
                                      [ngModel]="(selectedBestBeforeDateRange$ | async) || undefined"
                                      (onSelect)="filter($event.value)"
                                      [showSelectionOptions]="true"
                                      pTooltip="Single: Less than or equal to selected date.

                                  Range: Between selected dates (inclusive)."
                                      ngDefaultControl
                 />
              </ng-template>
            </p-columnFilter>

          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-stockPreparationItem let-index="rowIndex" let-editing="editing">
        <ng-container *ngIf="stockIdentity(stockPreparationItem) as stock">
          <tr pDraggable [pDraggableDisabled]="!isStockDraggable(stockPreparationItem)" (onDragStart)="notifyStockDragStart($event, stockPreparationItem)" (onDragEnd)="notifyStockDragEnd($event)">
            <td *ngIf="selectionEnabled$ | async">
              <p-tableCheckbox [value]="stockPreparationItem" />
            </td>
            <td> {{ index + 1 }}</td>
            <td>
              <foodbank-article-storage-condition [articleStorageCondition]="(stock.article$ | async)?.articleStorageCondition$ | async" />
            </td>
            <td class="white-space-normal">
              <foodbank-article ngDefaultControl [ngModel]="(stock.article$ | async) || undefined" />
            </td>
            <td class="white-space-normal">
              {{ stock.fullDescription }}
            </td>
            <td>
              {{ stock.internalBatch }}
            </td>
            <td class="aligned-quantity">
              {{ (stock.palletSummary$ | async)?.netWeight | number: '1.2-2' }}
            </td>
            <td class="aligned-quantity">
              {{ (stock.palletSummary$ | async)?.unitCount | number: '1.2-2' }}
            </td>
            <td>{{ stock.bestBeforeDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{ stock.deliverBeforeDate | date: 'dd/MM/yyyy' }}</td>
            <td>
              {{ stock.location }}
            </td>
            <td>
              <foodbank-pallet [pallet]="(stock.pallet$ | async) || undefined" [showHistory]="true" />
            </td>
            <td class="aligned-quantity">
              {{ stock.unitWeight | number: '1.2-2' }}
            </td>
            <td class="aligned-quantity">
              {{ stock.unitsPerParcel | number: '1.2-2' }}
            </td>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="19"><span i18n>{{ emptyMessage }}</span></td>
        </tr>

      </ng-template>
    </p-table>
  </ng-container>
</div>

import {UserDto} from '@typedefs/stock-rest';
import { EMPTY, Observable } from 'rxjs';
import {Warehouse} from '@model/warehouse';
import {Organization} from '@model/organization';
import {Company} from '@model/company';
import { SafeOmit } from "@model/mapping-utils";
import {ResourceRef} from "@angular/core";

/**
 * @deprecated use UserS
 */
export interface User extends SafeOmit<UserDto, 'companyId' | 'warehouseId' | 'organizationId' | 'bankId'> {
  company$: Observable<Company>;
  warehouse$: Observable<Warehouse | undefined>;
  organization$?: Observable<Organization>;
}

export interface UserS extends SafeOmit<UserDto, 'companyId' | 'warehouseId' | 'organizationId' | 'bankId'> {
  company: ResourceRef<Company>;
  warehouse: ResourceRef<Warehouse | undefined>;
  organization: ResourceRef<Organization |undefined>;
}

export function userNotFound(userId: string): User {
  return {
    id: userId,
    name: `${userId} (missing)`,
    company$: EMPTY,
    warehouse$: EMPTY,
    organization$: EMPTY,
    language: '',
    type: '',
    rights: '',
  };
}

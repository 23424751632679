import {Component, input, model} from '@angular/core';
import {Organization} from "@model/organization";
import {NgIf} from "@angular/common";

@Component({
    selector: 'foodbank-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss'],
    imports: [
        NgIf
    ]
})
export class OrganizationComponent {

  organization = model<Organization>();
  showId = input(false);
  showCode = input(false);
  showAddress = input(false);
}

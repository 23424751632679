import { Component, effect, inject, model, Signal, WritableSignal } from '@angular/core';
import { map } from "rxjs";
import { MovementType } from "@model/movement-type";
import { MovementTypeSearch } from "@model/search/movement-type-search";
import { Page } from "@typedefs/page";
import { PaginationService } from "@services/pagination.service";
import { Pagination } from "@services/pagination";
import { MovementTypeService } from "@services/movement-type.service";
import { DropdownModule } from "primeng/dropdown";
import { PaginatorModule } from "primeng/paginator";
import { MovementTypeComponent } from "@components/movement-type/movement-type.component";
import { MovementKind } from "@typedefs/stock-rest";
import { derivedAsync } from "ngxtension/derived-async";

@Component({
    selector: 'foodbank-movement-type-single-selection',
    templateUrl: './movement-type-single-selection.component.html',
    styleUrls: ['./movement-type-single-selection.component.scss'],
    imports: [
        DropdownModule,
        PaginatorModule,
        MovementTypeComponent
    ]
})
export class MovementTypeSingleSelectionComponent {


  ngModel = model<MovementType>();
  movementTypeSearch = model<MovementTypeSearch>({});
  excludeMovementKinds = model<MovementKind[]>([]);
  disabled = model(false);
  readonly = model(false);

  movementTypePage: Signal<Page<MovementType> | undefined>;

  pagination: WritableSignal<Pagination>;
  loading = false;

  #movementTypeService = inject(MovementTypeService);
  #paginationService = inject(PaginationService);

  constructor() {
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();
    this.movementTypePage = derivedAsync(() => {
      const excludedMovementKinds = this.excludeMovementKinds();
      console.log('Should exclude', excludedMovementKinds);
      return this.#movementTypeService.findMovementTypes$(this.movementTypeSearch(), this.pagination()).pipe(
        map(page => ({...page,
          content: page.content.filter(movementType => !excludedMovementKinds.includes(movementType.movementKind))
        }))
      )
    })

    effect(() => {
      console.debug('Querying ', this.movementTypeSearch());
      console.debug('Page ', this.pagination());
      this.loading = true;
    });

    effect(() => {
      console.debug('Found ', this.movementTypePage());
      this.loading = true;
    });
  }
}


import {Component, computed, inject, input, model, Signal} from '@angular/core';
import {SupplierSearch} from '@model/search/supplier-search';
import {SupplierService} from '@services/supplier.service';
import {Supplier} from '@model/supplier';
import {UserService} from '@services/user.service';
import {toSignal} from "@angular/core/rxjs-interop";
import {Page} from "@typedefs/page";
import {PaginationService} from "@services/pagination.service";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {SupplierComponent} from "@components/supplier/supplier.component";
import {NgForOf, NgIf} from "@angular/common";
import {derivedAsync} from "ngxtension/derived-async";

@Component({
  selector: 'foodbank-suppliers-selection',
  templateUrl: './suppliers-selection.component.html',
  styleUrls: ['./suppliers-selection.component.scss'],
  imports: [
    MultiSelectModule,
    FormsModule,
    SupplierComponent,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class SuppliersSelectionComponent {

  ngModel = model<Supplier[] | undefined>();
  supplierSearch = input<SupplierSearch>({
    includeCountrySuppliers: true,
    suppliers: [],
    supplierCategorySearch: {supplierCategoryList: []}
  });

  supplierPage: Signal<Page<Supplier> | undefined>;

  private supplierService = inject(SupplierService);
  private userService = inject(UserService);
  private paginationService = inject(PaginationService);

  constructor() {
    const currentUserCompany$ = this.userService.getCurrentUserCompany$();
    const currentUserCompany = toSignal(currentUserCompany$);

    const actualSupplierSearch = computed(() => ({
      ...this.supplierSearch(),
      company: currentUserCompany(),
    }));
    const pagination = this.paginationService.getDefaultPaginationSignal(200);

    this.supplierPage = derivedAsync(() => this.supplierService.findSuppliers$(actualSupplierSearch(), pagination()));
  }

}

import {Reception} from "@model/reception";
import {Article} from "@model/article";
import {Supplier} from "@model/supplier";
import {PalletType} from "@model/pallet-type";
import {StockGroup} from "@model/stock-group";
import {TransferRequest} from "@model/transfer-request";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {Warehouse} from "@model/warehouse";
import {ArticleCategory} from "@model/article-category";
import {ArticleStorageCondition} from "@model/article-storage-condition";
import {CachedResource} from "@util/cached-resource";
import {Injectable, Injector} from "@angular/core";
import {ArticleService} from "@services/article.service";
import {ArticleCategoryService} from "@services/article-category.service";
import {ArticleStorageConditionService} from "@services/article-storage-condition.service";
import {SupplierService} from "@services/supplier.service";
import {ReceptionService} from "@services/reception.service";
import {PalletTypeService} from "@services/pallet-type.service";
import {StockGroupService} from "@services/stock-group.service";
import {WarehouseService} from "@services/warehouse.service";
import {TransferRequestService} from "@services/transfer-request.service";
import {TransferRequestTargetService} from "@services/transfer-request-target.service";
import {FoodbankCache} from "@services/foodabank-cache";
import {User, UserS} from "@model/user";
import {UserService} from "@services/user.service";
import {Company} from "@model/company";
import {Organization} from "@model/organization";
import {CompanyService} from "@services/company.service";
import {OrganizationService} from "@services/organization.service";

@Injectable({
  providedIn: 'root'
})
export class FoodbankCacheFactory {

  create(injector: Injector): FoodbankCache {
    const articleService = injector.get(ArticleService);
    const articleCategoryService = injector.get(ArticleCategoryService);
    const articleStorageConditionService = injector.get(ArticleStorageConditionService);
    const supplierService = injector.get(SupplierService);
    const receptionService = injector.get(ReceptionService);
    const palletTypeService = injector.get(PalletTypeService);
    const stockGroupService = injector.get(StockGroupService);
    const warehouseService = injector.get(WarehouseService);
    const companyService = injector.get(CompanyService);
    const organizationService = injector.get(OrganizationService);
    const transferRequestService = injector.get(TransferRequestService);
    const transferRequestTargetService = injector.get(TransferRequestTargetService);
    const userService = injector.get(UserService);

    const cache: FoodbankCache = {
      articleCache: new CachedResource<string, Article>(injector, id => articleService.getArticle$(id)),
      articleCategoryCache: new CachedResource<string, ArticleCategory>(injector, id => articleCategoryService.findArticleCategory$(id)),
      articleStorageConditionCache: new CachedResource<string, ArticleStorageCondition>(injector, id => articleStorageConditionService.findArticleStorageCondition$(id)),
      supplierCache: new CachedResource<string, Supplier>(injector, id => supplierService.getSupplier$(id)),
      receptionCache: new CachedResource<number, Reception>(injector, id => receptionService.getReception$(id)),
      palletTypeCache: new CachedResource<string, PalletType>(injector, id => palletTypeService.getPalletType$(id)),
      stockGroupCache: new CachedResource<number, StockGroup>(injector, id => stockGroupService.getStockGroup$(id, injector, cache)),
      warehouseCache: new CachedResource<number, Warehouse>(injector, id => warehouseService.getWarehouse$(id)),
      companyCache: new CachedResource<string, Company>(injector, id => companyService.getCompany$(id)),
      organizationCache: new CachedResource<number, Organization>(injector, id => organizationService.getOrganization$(id)),
      transferRequestCache: new CachedResource<number, TransferRequest>(injector, id => transferRequestService.getTransferRequest$(id, injector)),
      transferRequestTargetCache: new CachedResource<number, TransferRequestTarget>(injector, id => transferRequestTargetService.getTransferRequestTarget$(id, injector, cache)),
      userCache: new CachedResource<string, UserS>(injector, id => userService.getUserS$(id, injector, cache)),
    };
    return cache;
  }

}

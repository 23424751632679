import {ChangeDetectionStrategy, Component, inject, Injector, signal, WritableSignal} from '@angular/core';
import {TransferRequestService} from "@services/transfer-request.service";
import {TransferRequest} from "@model/transfer-request";
import {injectParams} from "ngxtension/inject-params";
import {rxResource} from "@angular/core/rxjs-interop";
import {derivedAsync} from "ngxtension/derived-async";
import {UserService} from "@services/user.service";
import {TransferRequestEditComponent} from "@components/transfer/request/transfer-request-edit.component";
import {of} from "rxjs";
import {User} from "@model/user";
import {Company} from "@model/company";
import {Warehouse} from "@model/warehouse";

@Component({
  templateUrl: './transfer-request-page.component.html',
  imports: [
    TransferRequestEditComponent
  ],
  styleUrls: ['./transfer-request-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferRequestEditPageComponent {

  transferRequestIdParam = injectParams('id');
  transferRequestParam: WritableSignal<TransferRequest | undefined>;

  #transferRequestService = inject(TransferRequestService);
  #userService = inject(UserService);
  #injector = inject(Injector);

  constructor() {
    this.transferRequestParam = this.#getTransferRequestParam()
  }

  /**
   * Get transfer request from input param.
   */
  #getTransferRequestParam(): WritableSignal<TransferRequest | undefined> {
    const user = this.#userService.getCurrentUser();
    const company = this.#userService.getCurrentUserCompany();
    const warehouse = derivedAsync(() => company()?.warehouse$);
    const transferRequestResourceRef = rxResource({
      request: () => [this.transferRequestIdParam(), user(), company(), warehouse()],
      loader: ({request: [transferRequestIdString, user, company, warehouse]}) => {
        if (transferRequestIdString === 'new') {
          const newTransferRequest = this.#createTransferRequest(user as User, company as Company, warehouse as Warehouse);
          return of(newTransferRequest);
        }
        const transferRequestId = Number(transferRequestIdString);
        return this.#transferRequestService.getTransferRequest$(transferRequestId, this.#injector);
      }
    });
    return transferRequestResourceRef.value;
  }

  #createTransferRequest(user: User, company: Company, warehouse: Warehouse): TransferRequest {
    return {
      closed: false,
      company: signal(company),
      date: new Date(),
      user: signal(user),
      warehouse: signal(warehouse),
    }
  }
}

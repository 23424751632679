import {Component, inject, Input, numberAttribute, OnInit} from '@angular/core';
import {PreparationService} from '@services/preparation.service';
import {Observable} from 'rxjs';
import {Preparation} from '@model/preparation';
import { NgIf, AsyncPipe } from '@angular/common';
import { FeadPreparationComponent } from '../single/fead-preparation.component';
import { FeadPreparationDetailsComponent } from './fead-preparation-details.component';

@Component({
    selector: 'app-fead-preparation-details-standalone',
    templateUrl: './fead-preparation-details-standalone.component.html',
    styleUrls: ['./fead-preparation-details-standalone.component.scss'],
    imports: [NgIf, FeadPreparationComponent, FeadPreparationDetailsComponent, AsyncPipe]
})
export class FeadPreparationDetailsStandaloneComponent implements OnInit {

  @Input({alias: 'preparationId', transform: numberAttribute})
  preparationId?: number;
  @Input()
  expedition = false;

  preparation$?: Observable<Preparation>;

  preparationService = inject(PreparationService);

  ngOnInit(): void {
    if (this.preparationId) {
      this.preparation$ = this.preparationService.getPreparation$(this.preparationId);
    }
  }

}

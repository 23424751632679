import {Component, computed, effect, inject, Injector, linkedSignal, model, output, signal, Signal, WritableSignal} from '@angular/core';
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {TransferRequest} from "@model/transfer-request";
import {Pagination} from "@services/pagination";
import {Page} from "@typedefs/page";
import {derivedAsync} from "ngxtension/derived-async";
import {FormsModule} from "@angular/forms";
import {CheckboxModule} from "primeng/checkbox";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {TransferRequestItemSearch} from "@model/search/transfer-request-item-search";
import {TransferRequestItemService} from "@services/transfer-request-item.service";
import {DatePipe} from "@angular/common";
import {ArticleComponent} from "@components/article/article.component";
import {TransferRequestItemGroup} from "@model/transfer-request-item.group";
import {Warehouse} from "@model/warehouse";
import {debounceTime, of} from "rxjs";
import {TransferRequestTargetService} from "@services/transfer-request-target.service";
import {DragDropModule} from "primeng/dragdrop";
import {TabViewModule} from "primeng/tabview";
import {InputTextModule} from "primeng/inputtext";
import {TransferRequestItem} from "@model/transfer-request-item";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {ArticleSingleSelectionComponent} from "@components/article/selection/single/article-single-selection.component";
import {ArticleSearch} from "@model/search/article-search";
import {Article} from "@model/article";
import {Reception} from "@model/reception";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {DateSearch} from "@model/search/date-search";
import {DateSearchComponent} from "@components/date/date-search/date-search.component";
import {SupplierComponent} from "@components/supplier/supplier.component";
import {TransferRequestItemSortField} from "@typedefs/stock-rest";
import {SupplierSelectionComponent} from "@components/supplier/selection/single/supplier-selection.component";
import {Supplier} from "@model/supplier";
import {SupplierSearch} from "@model/search/supplier-search";
import {rxResource, toSignal} from "@angular/core/rxjs-interop";
import {Button} from "primeng/button";

type TransferRequestItemGroupWithTotal = TransferRequestItemGroup & { total: Signal<number | undefined> };

@Component({
  selector: 'foodbank-transfer-request-item-list',
  templateUrl: './transfer-request-item-list.component.html',
  imports: [
    TableModule,
    FormsModule,
    CheckboxModule,
    WarehouseComponent,
    TriStateCheckboxModule,
    DatePipe,
    ArticleComponent,
    DragDropModule,
    TabViewModule,
    InputTextModule,
    ArticleSingleSelectionComponent,
    DateSearchComponent,
    SupplierComponent,
    SupplierSelectionComponent,
    Button,
  ],
  styleUrls: ['./transfer-request-item-list.component.scss']
})
export class TransferRequestItemListComponent {

  transferRequest = model<TransferRequest>();
  transferRequestItemCount = output<number>();

  pagination: WritableSignal<Pagination>;
  transferRequestItemSearch: Signal<TransferRequestItemSearch>;
  transferRequestItemGroupWithTotalPage: Signal<Page<TransferRequestItemGroupWithTotal> | undefined>;
  transferRequestItemGroups: WritableSignal<TransferRequestItemGroup[]>;
  transferRequestTargetPage: Signal<Page<TransferRequestTarget> | undefined>;
  targetWarehouses: Signal<Warehouse[] | undefined>;

  articleSearch: Signal<ArticleSearch>;
  supplierSearch: Signal<SupplierSearch>;

  articleFilter = signal<Article | undefined>(undefined);
  supplierFilter = signal<Supplier | undefined>(undefined);
  receptionFilter = signal<Reception | undefined>(undefined);
  fullDescriptionFilter = signal<string | undefined>(undefined);
  deliverBeforeDateSearch = signal<DateSearch>({});

  #transferRequestItemService = inject(TransferRequestItemService);
  #transferRequestTargetService = inject(TransferRequestTargetService);
  #paginationService = inject(PaginationService);
  #injector = inject(Injector);

  constructor() {

    const debouncedFullDescriptionFilter = pipeSignal(this.fullDescriptionFilter, debounceTime(300));

    this.pagination = this.#paginationService.getDefaultPaginationSignal();
    this.transferRequestItemSearch = computed(() => ({
      transferRequest: this.transferRequest(),
      stockGroupSearch: {
        article: this.articleFilter(),
        supplier: this.supplierFilter(),
        reception: this.receptionFilter(),
        deliverBeforeDateSearch: this.deliverBeforeDateSearch(),
        fullDescriptionContains: debouncedFullDescriptionFilter(),
      },
    }));

    // column filters are derived from main search (= all filters and more), with their own search undefined to show all possible values
    this.articleSearch = computed(() => ({
      transferRequestItemSearch: {
        ...this.transferRequestItemSearch(),
        article: undefined,
        stockGroupSearch: {
          ...this.transferRequestItemSearch().stockGroupSearch,
          article: undefined,
        },
      },
    }));
    this.supplierSearch = computed(() => ({
      transferRequestItemSearch: {
        ...this.transferRequestItemSearch(),
        supplier: undefined,
        stockGroupSearch: {
          ...this.transferRequestItemSearch().stockGroupSearch,
          supplier: undefined,
        },
      },
    }));

    const transferRequestItemGroupPage = rxResource({
      request: () => ({
        transferRequestItemSearch: this.transferRequestItemSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#transferRequestItemService.findTransferRequestItemGroups$(param.request.transferRequestItemSearch, param.request.pagination, this.#injector)
    })

    // count number of total items
    effect(() => {
      const totalElements = transferRequestItemGroupPage.value()?.totalElements;
      if (totalElements) {
        this.transferRequestItemCount.emit(totalElements);
      }
    });

    this.transferRequestItemGroups = linkedSignal(() => transferRequestItemGroupPage.value()?.content || []);
    this.transferRequestItemGroupWithTotalPage = computed(() => {
      const page = transferRequestItemGroupPage.value();
      if (!page) {
        return undefined;
      }
      return ({
        ...page,
        content: this.transferRequestItemGroups().map(transferRequestItemGroup => this.#mapToTransferRequestItemGroupRow(transferRequestItemGroup)),
      });
    });

    // find warehouses
    this.transferRequestTargetPage = derivedAsync(() => !this.transferRequest() ? of() : this.#transferRequestTargetService.findTransferRequestTargets$({transferRequest: this.transferRequest()}, {page: 0, size: 0}, this.#injector));
    this.targetWarehouses = computed(() => this.transferRequestTargetPage()?.content
      .map(transferRequestTarget => transferRequestTarget.targetWarehouse.value()).filter(warehouse => !!warehouse));
  }

  handleLazyLoadEvent(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  typing(transferRequestItemGroup: TransferRequestItemGroupWithTotal): TransferRequestItemGroupWithTotal {
    return transferRequestItemGroup;
  }

  updatePalletQuantity(transferRequestItem: TransferRequestItem, newPalletQuantity: number) {
    transferRequestItem.palletQuantity = newPalletQuantity;
    this.#saveTransferRequestItem(transferRequestItem);
  }

  assignPalletQuantity(transferRequestItemGroup: TransferRequestItemGroup, targetWarehouse: Warehouse, palletQuantity: number) {
    const warehouseTransferRequestItemMap = transferRequestItemGroup.warehouseTransferRequestItemMap();
    const transferRequestTarget = this.#getTransferRequestTarget(targetWarehouse);
    const transferRequestItem = warehouseTransferRequestItemMap.get(targetWarehouse.id) ?? {
      stockGroup: transferRequestItemGroup.stockGroup,
      transferRequestTarget: signal(transferRequestTarget),
      palletQuantity: palletQuantity,
    };
    this.#saveTransferRequestItem(transferRequestItem);
  }

  sortFieldName(sortField: TransferRequestItemSortField): TransferRequestItemSortField {
    return sortField;
  }

  transfer(targetWarehouse: Warehouse) {
    const transferRequestTarget = this.#getTransferRequestTarget(targetWarehouse);
    if (transferRequestTarget) {
      this.#transferRequestTargetService.transfer$(transferRequestTarget, this.#injector)
        .subscribe(() => this.#refresh());
    }
  }

  #refresh() {
    this.pagination.set({...this.pagination()});
  }

  #saveTransferRequestItem(transferRequestItem: TransferRequestItem) {
    const transferRequestItemGroupsBeforeUpdate = this.transferRequestItemGroups();
    const transferRequestItemUpdateObservable = this.#transferRequestItemService.updateTransferRequestItem$(transferRequestItem, this.#injector);
    const transferRequestItemSignal = toSignal(transferRequestItemUpdateObservable, {injector: this.#injector});
    effect(() => {
      const updatedTransferRequestItem = transferRequestItemSignal();
      if (updatedTransferRequestItem) {
        const transferRequestItemGroupsWithReplacements = this.#replaceTransferRequestItemInGroups(transferRequestItemGroupsBeforeUpdate, updatedTransferRequestItem);
        this.transferRequestItemGroups.set(transferRequestItemGroupsWithReplacements);
      }
    }, {injector: this.#injector});
  }

  #replaceTransferRequestItemInGroups(transferRequestItemGroups: TransferRequestItemGroup[], replacementTransferRequestItem: TransferRequestItem): TransferRequestItemGroup[] {
    const transferRequestTarget = replacementTransferRequestItem.transferRequestTarget();
    const targetWarehouse = transferRequestTarget?.targetWarehouse.value();
    if (!targetWarehouse) {
      return transferRequestItemGroups;
    }
    // find TransferRequestItemGroup
    const transferRequestItemGroupIndex = transferRequestItemGroups.findIndex(transferRequestItemGroup => transferRequestItemGroup.stockGroup()?.id === replacementTransferRequestItem.stockGroup()?.id);
    if (transferRequestItemGroupIndex < 0) {
      // not found, don't replace
      return transferRequestItemGroups;
    }
    const transferRequestItemGroupToReplace = transferRequestItemGroups[transferRequestItemGroupIndex];
    const warehouseTransferRequestItemMap = transferRequestItemGroupToReplace.warehouseTransferRequestItemMap();
    // copy & replace
    const updatedWarehouseTransferRequestItemMap = new Map(warehouseTransferRequestItemMap);
    updatedWarehouseTransferRequestItemMap.set(targetWarehouse.id, replacementTransferRequestItem);
    const updatedTransferRequestItemGroup: TransferRequestItemGroup = {
      ...transferRequestItemGroupToReplace,
      warehouseTransferRequestItemMap: signal(updatedWarehouseTransferRequestItemMap),
    };

    const transferRequestItemGroupsWithReplacements = [...transferRequestItemGroups];
    transferRequestItemGroupsWithReplacements[transferRequestItemGroupIndex] = updatedTransferRequestItemGroup;
    return transferRequestItemGroupsWithReplacements;
  }

  #mapToTransferRequestItemGroupRow(transferRequestItemGroup: TransferRequestItemGroup): TransferRequestItemGroupWithTotal {
    return {
      ...transferRequestItemGroup,
      total:
        computed(() => {
          const transferRequestItems = transferRequestItemGroup.warehouseTransferRequestItemMap().values();

          const transferRequestItemArray = Array.from(transferRequestItems);
          const total = transferRequestItemArray.map(transferRequestItem => transferRequestItem.palletQuantity)
            .reduce((quantity1, quantity2) => this.#add(quantity1, quantity2), undefined);
          return total;
        })
    }
  }

  #add(quantity1?: number, quantity2?: number): number | undefined {
    if (!quantity1 && !quantity2) {
      return undefined;
    }
    return (quantity1 ?? 0) + (quantity2 ?? 0);
  }

  #getTransferRequestTarget(targetWarehouse: Warehouse): TransferRequestTarget | undefined {
    const transferRequestTargets = this.transferRequestTargetPage()?.content;
    const matchingTransferRequestTargets = transferRequestTargets?.filter(transferRequestTarget => transferRequestTarget.targetWarehouse.value()?.id === targetWarehouse.id);
    return matchingTransferRequestTargets ? matchingTransferRequestTargets[0] : undefined;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  protected readonly Date = Date;
}

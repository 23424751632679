<ng-container *ngIf="(receptionItem.reception$ | async) as reception">
  <div class="grid">
    <div class="col-12 flex flex-row">
      <label class="flex-initial flex align-items-center font-bold m-2 border-round"
             for="reception-description"
             i18n>Description</label>
      <span id="reception-description"
            class="text-lg p-1 w-6 flex-initial flex align-items-center border-round mt-1 mb-1">{{ receptionItem.description }}</span>
    </div>
  </div>
  <div class="grid">
    <div class="col-4 flex flex-column">
      <div class="flex flex-row">
        <label class="w-5 flex-initial flex align-items-center font-bold m-2 border-round"
               for="reception-article"
               i18n>Article</label>
        <foodbank-article id="reception-article"
                          class="p-1 flex-initial flex align-items-center border-round mt-1 mb-1"
                          [ngModel]="(receptionItem.article$ | async) || undefined"
                          ngDefaultControl
                          [showId]="true" />
      </div>
      <div class="flex flex-row">
        <label class="w-5 flex-initial flex align-items-center font-bold m-2 border-round"
               for="reception-returnable"
               i18n>Returnable</label>
        <div class="p-1 flex-initial flex align-items-center border-round mt-1 mb-1">
          <ng-container *ngIf="receptionItem.returnable$ | async as returnable; else noReturnable">
            <foodbank-returnable id="reception-returnable"
                                 [ngModel]="$any(returnable)" ngDefaultControl/>
          </ng-container>
          <ng-template #noReturnable>
            <span id="reception-no-returnable" i18n>None</span>
          </ng-template>
        </div>
      </div>
      <div class="flex flex-row">
        <label class="w-5 flex-initial flex align-items-center font-bold m-2 border-round"
               for="no-of-pallets"
               i18n>No of pallets</label>

              <span class="text-lg p-1 flex-initial flex align-items-center border-round mt-1 mb-1" id="no-of-pallets">{{ (receptionItemSummary$ | async)?.palletCount }}
              <ng-container *ngIf="(receptionItemSummary$ | async)?.palletCount">
                <i class="pi pi-info-circle ml-1" (click)="pallets.toggle($event, palletsInfo)"></i>
                <p-overlayPanel #pallets [style]="{ width: '40%', 'min-width': '770px' }">
                  <ng-template pTemplate="content">
                    <foodbank-reception-pallet-cluster-table [receptionItem]="receptionItem" />
                  </ng-template>
                </p-overlayPanel>
              </ng-container>
              <ng-container *ngIf="(receptionItemSummary$ | async)?.palletCount === 0">
                <i class="pi pi-exclamation-triangle text-red-600 ml-1" [pTooltip]="'No pallets defined'"></i>
              </ng-container>
              </span>
      </div>
      <div class="flex flex-row">
        <label #palletsInfo class="w-5 flex-initial flex align-items-center font-bold m-2 border-round"
               for="net-weight" i18n>Net weight (Kg)</label>
        <span class="text-lg p-1 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="net-weight">{{ (receptionItemSummary$ | async)?.netWeight | number: '1.2-2' }}

          <i class="fa-solid fa-calculator ml-1" (mouseenter)="quantityInfo.show($event)"
             (mouseleave)="quantityInfo.hide()"></i>
              <th>
              <p-overlayPanel #quantityInfo>
                <ng-template pTemplate="content">
                  <span i18n>
                    <span class="font-bold">No of parcels/pallet</span> X
                    <span class="font-bold">No of pallets</span> X
                    <span class="font-bold">Units per parcel</span> X
                    <span class="font-bold">Net unit weight (gr)</span> / <span class="font-semibold">1000</span>
                  </span>
                </ng-template>
              </p-overlayPanel>

          </span>
      </div>
    </div>
    <div class="col-4 flex flex-column">
      <div class="flex flex-row">
        <label class="w-8 flex-initial flex align-items-center font-bold m-2 border-round"
               for="gross-unit-weight" i18n>Gross unit weight (gr)</label>
        <span class="text-lg p-1 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="gross-unit-weight">{{ receptionItem.unitGrossWeight | number: '1.0-0' }}</span>
      </div>
      <div class="flex flex-row">
        <label class="w-8 flex-initial flex align-items-center font-bold m-2 border-round"
               for="net-unit-weight" i18n>Net unit weight (gr)</label>
        <span class="text-lg p-1 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="net-unit-weight">{{ receptionItem.unitWeight | number: '1.0-0' }}</span>
      </div>
      <div class="flex flex-row">
        <label class="w-8 flex-initial flex align-items-center font-bold m-2 border-round"
               for="units-per-parcel"
               i18n>Units per parcel</label>
        <span class="text-lg p-1 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="units-per-parcel">{{ receptionItem.unitsPerParcel }}</span>
      </div>
      <div class="flex flex-row">
        <label class="w-8 flex-initial flex align-items-center font-bold m-2 border-round"
               for="parcels"
               i18n>No of parcels</label>
        <span class="text-lg p-1 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="parcels">{{ (receptionItemSummary$ | async)?.parcelCount }}</span>
      </div>

    </div>
    <div class="col-4 flex flex-column">
      <div class="flex flex-row">
        <label class="w-6 flex-initial flex align-items-center font-bold m-2 border-round" for="supplier-lot"
               i18n>Supplier lot</label>
        <span class="text-lg p-1 w-6 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="supplier-lot">{{ receptionItem.supplierLot }}</span>
      </div>
      <div class="flex flex-row">
        <label class="w-6 flex-initial flex align-items-center font-bold m-2 border-round" for="internal-lot"
               i18n>Internal lot</label>
        <span class="text-lg p-1 w-6 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="internal-lot">{{ receptionItem.internalLot }}</span>
      </div>
      <div class="flex flex-row">
        <label class="w-6 flex-initial flex align-items-center font-bold m-2 border-round"
               for="expiration-date"
               i18n>Expiration date</label>
        <span class="w-6 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="expiration-date">{{ receptionItem.expirationDate | date: 'dd/MM/yyyy' }}</span>
      </div>
      <div class="flex flex-row">
        <label class="w-6 flex-initial flex align-items-center font-bold m-2 border-round"
               for="best-before-date"
               i18n>Best before date</label>
        <span class="w-6 flex-initial flex align-items-center border-round mt-1 mb-1"
              id="best-before-date">{{ receptionItem.bestBeforeDate | date: 'dd/MM/yyyy' }}</span>
      </div>

    </div>
  </div>
</ng-container>

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject, combineLatest, distinctUntilChanged, Observable, shareReplay, switchMap} from 'rxjs';
import {Page} from '@typedefs/page';
import {FeadPlanningPagination} from '@services/fead-planning-summary.service';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import {PaginationService} from '@services/pagination.service';
import {FeadPlanningService} from '@services/fead-planning.service';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';
import {FeadPlanningLine} from '@model/fead-planning-line';
import {Pagination} from '@services/pagination';
import { PrimeTemplate } from 'primeng/api';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { ArticleComponent } from '../../article/article.component';
import { FormsModule } from '@angular/forms';
import { WarehouseComponent } from '../../warehouse/warehouse.component';
import { OrganizationComponent } from '../../organization/organization.component';


@Component({
    selector: 'foodbank-fead-planning',
    templateUrl: './fead-planning.component.html',
    styleUrls: ['./fead-planning.component.scss'],
    imports: [TableModule, PrimeTemplate, NgIf, ArticleComponent, FormsModule, WarehouseComponent, OrganizationComponent, AsyncPipe, DecimalPipe]
})
export class FeadPlanningComponent implements OnInit, OnChanges {

  protected readonly DEFAULT_ROWS_PER_PAGE = 50;

  @Input()
  feadPlanningSearch: FeadPlanningSearch = {};

  private feadPlanningSearch$!: BehaviorSubject<FeadPlanningSearch>;
  private pagination$!: BehaviorSubject<FeadPlanningPagination>;

  feadPlanningLinePage$!: Observable<Page<FeadPlanningLine>>;

  constructor(private feadPlanningService: FeadPlanningService,
              private paginationService: PaginationService) {
  }

  ngOnInit(): void {
    this.feadPlanningSearch$ = new BehaviorSubject<FeadPlanningSearch>(this.feadPlanningSearch);

    const defaultPagination = this.paginationService.getDefaultPagination();
    this.pagination$ = new BehaviorSubject<Pagination>(defaultPagination);

    this.feadPlanningLinePage$ = combineLatest([this.pagination$, this.feadPlanningSearch$]).pipe(
      distinctUntilChanged(),
      switchMap(([pagination, feadPlanningSearch]) => this.feadPlanningService.getFeadPlanning$(feadPlanningSearch, pagination)),
      shareReplay(1)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.feadPlanningSearch$?.next(this.feadPlanningSearch);
  }

  loadFeadPlanning(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination$.next(pagination);
  }

  identity(feadPlanningLine: any): FeadPlanningLine {
    return feadPlanningLine;
  }
}

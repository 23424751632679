import {Component, computed, inject, input, model, signal, Signal, Type, WritableSignal} from '@angular/core';
import {take} from "rxjs";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import {Page} from '@typedefs/page';
import { MessageService, PrimeTemplate } from 'primeng/api';

import {PreparationSearch} from '@model/search/preparation-search';
import {Preparation} from '@model/preparation';
import {PreparationPagination, PreparationService} from '@services/preparation.service';
import {UserService} from '@services/user.service';
import {Warehouse} from '@model/warehouse';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {FeadPreparationNewComponent} from "@components/fead/preparation/new/fead-preparation-new.component";
import {PreparationNewComponent} from "@components/preparation/new/preparation-new.component";
import {toSignal} from "@angular/core/rxjs-interop";
import {computedFromObservable} from "@util/foodbanks-signal-rxjs-interop";
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { Button, ButtonDirective } from 'primeng/button';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { WarehouseSingleSelectionComponent } from '../../../warehouse/selection/single/warehouse-single-selection.component';
import { WarehouseComponent } from '../../../warehouse/warehouse.component';
import { PreparationZoneComponent } from '../../../preparation-zone/preparation-zone.component';
import { Ripple } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { FeadPreparationDetailsComponent } from '../detail/fead-preparation-details.component';

@Component({
    selector: 'foodbank-fead-preparation-list',
    templateUrl: './fead-preparation-list.component.html',
    styleUrls: ['./fead-preparation-list.component.scss'],
    imports: [TableModule, PrimeTemplate, NgIf, Button, ToggleButtonModule, FormsModule, InputTextModule, WarehouseSingleSelectionComponent, WarehouseComponent, PreparationZoneComponent, ButtonDirective, Ripple, TooltipModule, ToastModule, FeadPreparationDetailsComponent, AsyncPipe, DatePipe]
})
export class FeadPreparationListComponent {

  // toggleable or from routing
  expedition = model(false);

  fead = input<boolean>();
  // selected filters
  warehouse = signal<Warehouse | undefined>(undefined);

  // main search
  preparationSearch: Signal<PreparationSearch>;

  pagination: WritableSignal<PreparationPagination>;
  preparationPage: Signal<Page<Preparation> | undefined>;

  // searches for table filters
  warehouseSearch: Signal<WarehouseSearch>;

  // selected table row
  selectedPreparation = signal<Preparation | undefined>(undefined);

  modalDialogRef?: DynamicDialogRef;

  refreshPreparationTrigger = signal(0); // child components won't see changes on same object instance if we don't cheat like this

  private readonly preparationService = inject(PreparationService);
  private readonly userService = inject(UserService);
  private readonly paginationService = inject(PaginationService);
  private readonly messageService = inject(MessageService);
  private readonly dialogService = inject(DialogService);

  constructor() {
    const defaultPagination = this.paginationService.getDefaultPagination();
    this.pagination = signal(defaultPagination);

    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    defaultWarehouse$.subscribe(this.warehouse.set)

    const company$ = this.userService.getCurrentUserCompany$();
    const company = toSignal(company$);

    this.preparationSearch = computed<PreparationSearch>(() => ({
        company: company(),
        type: this.fead() ? 'ESF_PLUS' : 'NON_ESF_PLUS',
        warehouse: this.warehouse(),
        closed: false,
      })
    );

    this.warehouseSearch = computed<WarehouseSearch>(() => ({
        company: company(),
        active: true,
        preparationSearch: {
          ...this.preparationSearch(),
          warehouse: undefined
        }
      })
    );

    this.preparationPage = computedFromObservable(() => this.preparationService.find$(this.preparationSearch(), this.pagination()));
  }

  lazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  close(preparation: Preparation) {
    this.preparationService.setClosed$(preparation, true).subscribe({
      next: _ => {
        this.messageService.add({key: 'closed', severity: 'success', summary: 'Success', detail: `Preparation #${preparation.id} closed`, data: preparation});
        this.refresh();
      },
      error: _ => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to close preparation #${preparation.id}`})
      }
    });
  }

  reopenPreparation(preparation: Preparation) {
    this.preparationService.setClosed$(preparation, false).subscribe({
      next: _ => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: `Preparation #${preparation.id} reopened`, data: preparation});
        this.refresh();
      },
      error: _ => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to reopen preparation #${preparation.id}`})
      }
    });
  }

  openNewESFPreparationDialog() {
    return this.openGenericPreparationDialog('New ESF+ preparation', FeadPreparationNewComponent);
  }

  openNewPreparationDialog() {
    return this.openGenericPreparationDialog('New preparation', PreparationNewComponent);
  }

  openEditPreparationDialog(preparation: Preparation) {
    return this.openGenericPreparationDialog('Edit preparation ' + preparation.id, PreparationNewComponent, preparation);
  }

  openGenericPreparationDialog(title: string, componentType: Type<any>, preparation?: Preparation) {
    this.modalDialogRef = this.dialogService.open(componentType, {
      header: title,
      width: '90%',
      height: '90%',
      data: preparation
    });

    this.modalDialogRef.onClose.subscribe(preparation => this.onPreparationModalClosed(preparation));
  }

  private onPreparationModalClosed(preparation?: Preparation) {
    preparation?.warehouse$
      .pipe(take(1))
      .subscribe(warehouse => {
        this.warehouse.set(warehouse);
        this.refresh();
        this.refreshPreparation();
      });
  }

  private refresh() {
    this.pagination.update(pagination => ({...pagination}));
  }

  private refreshPreparation() {
    this.refreshPreparationTrigger.update(i => i + 1);
  }
}

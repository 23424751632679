import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectButtonModule} from "primeng/selectbutton";
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'foodbank-table-size',
    templateUrl: './table-size.component.html',
    styleUrls: ['./table-size.component.scss'],
    imports: [
        SelectButtonModule,
        FormsModule
    ]
})
export class TableSizeComponent implements OnInit {
  @Input() tableStyleClass: string = '';
  @Output() tableStyleClassChange = new EventEmitter<string>();

  options = [
    {label: 'extra-small', icon: 'pi pi-search-minus pi-search-minus-xs', class: 'p-datatable-xs'},
    {label: 'small', icon: 'pi pi-search-minus', class: 'p-datatable-sm'},
    {label: 'normal', icon: 'pi pi-search', class: ''},
    {label: 'large', icon: 'pi pi-search-plus', class: 'p-datatable-lg'}
  ];

  ngOnInit() {
    if (!this.tableStyleClass || this.tableStyleClass.length === 0) {
      this.tableStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || ''
    }

  }

  update(value: string) {
    this.tableStyleClassChange.emit(value);
    localStorage.setItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION', value);
  }

}

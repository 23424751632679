import {Component, OnInit} from '@angular/core';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';
import {Warehouse} from '@model/warehouse';
import {PreparationService} from "@services/preparation.service";
import {BehaviorSubject, combineLatest, map, mergeMap, Observable, of, shareReplay} from "rxjs";
import {Preparation} from "@model/preparation";
import {PreparationSearch} from '@model/search/preparation-search';
import {UserService} from '@services/user.service';
import {PaginationService} from '@services/pagination.service';
import {Company} from '@model/company';
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { FeadCampaignSelectionComponent } from '../../../fead-campaign/fead-campaign-selection/fead-campaign-selection.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { WarehouseComponent } from '../../../warehouse/warehouse.component';
import { FeadPreparationComponent } from '../single/fead-preparation.component';
import { Button } from 'primeng/button';
import { FeadPlanningSummaryTableComponent } from '../../../fead-planning/summary/table/fead-planning-summary-table.component';

@Component({
    selector: 'foodbank-fead-preparation-new',
    templateUrl: './fead-preparation-new.component.html',
    styleUrls: ['./fead-preparation-new.component.scss'],
    imports: [FeadCampaignSelectionComponent, NgIf, CheckboxModule, FormsModule, WarehouseComponent, FeadPreparationComponent, Button, FeadPlanningSummaryTableComponent, AsyncPipe]
})
export class FeadPreparationNewComponent implements OnInit {

  feadPlanningSearch!: FeadPlanningSearch;
  relevantOnly = true;

  selectedPeriod?: string;
  selectedWarehouse?: Warehouse;

  selectedPeriodSubject!: BehaviorSubject<string | undefined>;
  selectedWarehouseSubject!: BehaviorSubject<Warehouse | undefined>;
  loading$ = new BehaviorSubject<boolean>(false);

  existingPreparation$?: Observable<Preparation | undefined>;
  canCreatePreparation$!: Observable<boolean>;

  constructor(
    private preparationService: PreparationService,
    private paginationService: PaginationService,
    private userService: UserService,
    private messageService: MessageService,
    private ref: DynamicDialogRef
  ) {
  }

  ngOnInit(): void {
    const year = new Date().getFullYear();
    this.feadPlanningSearch = {
      year: year,
      startPeriod: `${year}/01`,
      endPeriod: `${year}/52`,
    }

    this.selectedPeriodSubject = new BehaviorSubject<string | undefined>(undefined);
    this.selectedWarehouseSubject = new BehaviorSubject<Warehouse | undefined>(undefined);

    // search for existing preparation
    const userCompany$ = this.userService.getCurrentUserCompany$();
    const existingPreparationSearch$: Observable<undefined | PreparationSearch> = combineLatest([userCompany$, this.selectedPeriodSubject, this.selectedWarehouseSubject]).pipe(
      map(([userCompany, period, warehouse]) => this.getExistingPreparationSearch(userCompany, period, warehouse))
    );
    this.existingPreparation$ = existingPreparationSearch$.pipe(
      mergeMap(existingPreparationSearch => this.findExistingPreparation$(existingPreparationSearch)),
      shareReplay()
    );
    this.canCreatePreparation$ = combineLatest([this.loading$, existingPreparationSearch$, this.existingPreparation$]).pipe(
      // you can create a preparation if you select enough data to check if there is an existing one + if there actually is none yet
      map(([loading, existingPreparationSearch, existingPreparation]) => !loading && !!(existingPreparationSearch && !existingPreparation))
    )
  }

  private findExistingPreparation$(existingPreparationSearch?: PreparationSearch) {
    if (!existingPreparationSearch) {
      // no search, can't find any preparation
      return of(undefined);
    }
    return this.preparationService.find$(existingPreparationSearch, this.paginationService.getSingleItemPatination()).pipe(
      map(page => page.content.pop())
    );
  }

  private getExistingPreparationSearch(userCompany: Company, period?: string, warehouse?: Warehouse): PreparationSearch | undefined {
    if (!period || !warehouse) {
      // not enough info to find existing preparation
      return undefined;
    }
    return {
      company: userCompany,
      location: period,
      warehouse: warehouse,
      type: 'ESF_PLUS',
      closed: false,
    };
  }

  selectCampaign(campaign: FeadCampaignIdentifier) {
    this.feadPlanningSearch = {
      ...this.feadPlanningSearch,
      year: campaign.year,
    }
  }

  selectPeriods(periods: string[]) {
    this.selectedPeriod = periods.length==1 ? periods[0]:undefined
    this.selectedPeriodSubject.next(this.selectedPeriod);
  }

  selectWarehouses(warehouses: Warehouse[]) {
    this.selectedWarehouse = warehouses.length==1 ? warehouses[0]:undefined
    this.selectedWarehouseSubject.next(this.selectedWarehouse);
  }

  createPreparation(year: number, period: string, warehouse: Warehouse) {
    this.loading$.next(true);
    this.preparationService.createPreparation$(year, period, warehouse)
      .subscribe({
        next: preparation => {
          this.messageService.add({severity: 'success', summary: 'A new preparation has been created', detail: `Preparation ${preparation.id} created`});
          this.ref.close(preparation);
        },
        error: error => {
          this.loading$.next(false);
          this.messageService.add({severity: 'error', summary: 'Error creating preparation', detail: 'An error occurred while creating the preparation.\nDetails: ' + error.message || 'Unknown error'});
          console.error('Error creating preparation', error);
        }
      });
  }

  createPreparationForSelection() {
    this.selectedPeriod && this.selectedWarehouse && this.feadPlanningSearch.year && this.createPreparation(this.feadPlanningSearch.year, this.selectedPeriod, this.selectedWarehouse);
  }

}

import {Component, model} from '@angular/core';
import {Zone} from '@model/zone';
import { NgIf } from '@angular/common';
import { LanguageDirective } from '../../directives/language.directive';

@Component({
    selector: 'foodbank-preparation-zone',
    templateUrl: './preparation-zone.component.html',
    styleUrls: ['./preparation-zone.component.scss'],
    imports: [NgIf, LanguageDirective]
})
export class PreparationZoneComponent {

  ngModel = model<Zone>();

}

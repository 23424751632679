import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';
import {Warehouse} from '@model/warehouse';
import {map, Observable, tap} from 'rxjs';
import {FeadCampaignService} from '@services/fead-campaign.service';
import {FeadCampaign} from '@model/fead-campaign';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { PrimeTemplate } from 'primeng/api';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'foodbank-fead-campaign-selection',
    templateUrl: './fead-campaign-selection.component.html',
    styleUrls: ['./fead-campaign-selection.component.scss'],
    imports: [DropdownModule, FormsModule, PrimeTemplate, NgIf, AsyncPipe]
})
export class FeadCampaignSelectionComponent implements OnInit {

  @Input() warehouse?: Warehouse;
  @Output() onCampaignSelected: EventEmitter<FeadCampaignIdentifier> = new EventEmitter<FeadCampaignIdentifier>();

  campaigns$!: Observable<FeadCampaignIdentifier[]>;
  selectedCampaign?: FeadCampaignIdentifier;
  loading: boolean = true;

  constructor(private feadCampaignService: FeadCampaignService) {
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.campaigns$ = this.feadCampaignService.findFeadCampaignIdentifiers$( {warehouseId: this.warehouse?.id})
        .pipe(
          map(campaigns => campaigns.sort((feadCampaignIdentifier1, feadCampaignIdentifier2) => feadCampaignIdentifier1.year - feadCampaignIdentifier2.year)),
          tap(campaigns => this.selectCampaignClosestToCurrentYear(campaigns, currentYear)),
        );
  }

  select(campaign: FeadCampaignIdentifier) {
    this.selectedCampaign = campaign;
    this.onCampaignSelected.emit(campaign);
  }

  private selectCampaignClosestToCurrentYear(campaigns: FeadCampaignIdentifier[], currentYear: number) {
    const closestCampaign = campaigns.reduce((closest, campaign) => Math.abs(campaign.year - currentYear) <= Math.abs(closest.year - currentYear) ? campaign : closest);
    this.select(closestCampaign);
    this.loading = false;
  }

}

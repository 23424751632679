<p-table #dt
         [value]="stockGroupWithSummaryPage()?.content || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="stockGroupWithSummaryPage()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="loadStockGroupList($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         selectionMode="multiple"
         [(selection)]="selectedStockGroups"
         [multiSortMeta]="[{ field: 'ARTICLE_CATEGORY_ID', order: 1 }, { field: 'ARTICLE_ID', order: 1 }]"
         [styleClass]="tableSizeStyleClass"
         [scrollable]="true"
         [scrollHeight]="'calc(100vh - 350px)'"
>

  <ng-template pTemplate="caption" *ngIf="!embedded()">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Stock Groups</h2>
    </div>
    <div class="flex flex-column md:flex-row mt-5 justify-content-between">
      <div class="field">
        <foodbank-table-size [(tableStyleClass)]="tableSizeStyleClass"/>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th></th>
      <th [pSortableColumn]="sortFieldName('ARTICLE_CATEGORY_ID')">
        <span i18n>Category</span>
        <p-sortIcon [field]="sortFieldName('ARTICLE_CATEGORY_ID')"/>
      </th>
      <th [pSortableColumn]="sortFieldName('ARTICLE_ID')" pResizableColumn>
        <span i18n>Article</span>
        <p-sortIcon [field]="sortFieldName('ARTICLE_ID')"/>
      </th>
      <th [pSortableColumn]="sortFieldName('SUPPLIER_ID')" pResizableColumn>
        <span i18n>Supplier</span>
        <p-sortIcon [field]="sortFieldName('SUPPLIER_ID')"/>
      </th>
      <th [pSortableColumn]="sortFieldName('RECEPTION_ID')">
        <span i18n>Reception</span>
        <p-sortIcon [field]="sortFieldName('RECEPTION_ID')"/>
      </th>
      <th [pSortableColumn]="sortFieldName('PALLET_TYPE')">
        <span i18n>Pallet type</span>
        <p-sortIcon [field]="sortFieldName('PALLET_TYPE')"/>
      </th>
      <th>
        <span i18n>Pallets 🥞</span>
      </th>
      <th>
        <span i18n>Total weight 🏋️</span>
      </th>
      <th [pSortableColumn]="sortFieldName('DELIVER_BEFORE_DATE')">
        <span i18n>DLD 🗓️</span>
        <p-sortIcon [field]="sortFieldName('DELIVER_BEFORE_DATE')"/>
      </th>
      <th [pSortableColumn]="sortFieldName('FULL_DESCRIPTION')">
        <span i18n>Full description</span>
        <p-sortIcon [field]="sortFieldName('FULL_DESCRIPTION')"/>
      </th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter field="articleCategories" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-article-category-selection ngDefaultControl
                                                 [selectedArticleCategories]="selectedArticleCategories()"
                                                 [articleCategorySearch]="filterArticleCategorySearch() || {}"
                                                 (onArticleCategorySelected)="selectedArticleCategories.set($event)"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-article-multi-selection ngDefaultControl
                                              [selectedArticles]="selectedArticles()"
                                              [articleSearch]="filterArticleSearch() || undefined"
                                              (onArticleSelected)="selectedArticles.set($event)"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="suppliers" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-supplier-selection ngDefaultControl
                                         [(ngModel)]="selectedSupplier"
                                         [supplierSearch]="filterSupplierSearch()"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="fullDescriptionContains"/>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-stockGroupWithSummary>
    <ng-container *ngIf="typing(stockGroupWithSummary) as stockGroupWithSummary">
      @let stockGroup = stockGroupWithSummary.stockGroup;
      <tr pDraggable (onDragStart)="notifyStockGroupDragStart($event, stockGroup)" (onDragEnd)="notifyStockGroupDragEnd($event)">
        <td>
          <p-tableCheckbox [value]="stockGroup"/>
        </td>
        <td>
          <!-- TODO: any should go, it's there because article$ is not always defined correctly (undefined?), still passes ?. but then fails type checking -->
          <foodbank-article-category ngDefaultControl
                                     [articleCategory]="stockGroup.article.value()?.articleCategory$ | async"/>
        </td>
        <td class="white-space-normal">
          <foodbank-article ngDefaultControl [ngModel]="stockGroup.article.value()"/>
        </td>
        <td class="white-space-normal">
          <foodbank-supplier ngDefaultControl [ngModel]="stockGroup.supplier.value()"/>
        </td>
        <td>
          @let reception = stockGroup.reception.value();
          @if (reception) {
            #{{ reception.id }}
            {{ reception.date | date: 'dd/MM/yyyy' }}
          }
        </td>
        <td>
          <foodbank-pallet-type ngDefaultControl [palletType]="stockGroup.palletType.value()"/>
        </td>
        <td class="aligned-quantity">
          {{ stockGroupWithSummary.stockSummary.numberOfPallets }}
        </td>
        <td class="aligned-quantity">
          <foodbank-number [value]="stockGroupWithSummary.stockSummary.totalWeight" />
        </td>
        <td>
          {{ stockGroup.deliverBeforeDate | date: 'dd/MM/yyyy' }}
        </td>
        <td>
          {{ stockGroup.fullDescription }}
        </td>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="17"><span i18n>{{ emptyMessage() }}</span></td>
    </tr>
  </ng-template>
</p-table>

import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, combineLatest, defaultIfEmpty, EMPTY, forkJoin, map, mergeMap, Observable, of, shareReplay, take} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {Stock} from '@model/stock';
import {WarehouseService} from './warehouse.service';
import {StockSearch} from '@model/search/stock-search';
import {ArticleService} from './article.service';
import {InternalBatchSearchDto, StockDto, StockPalletSummaryDto, StockSearchDto, StockSummaryDto, SupplierBatchSearchDto} from '@typedefs/stock-rest';
import {CompanyService} from '@services/company.service';
import {SupplierService} from '@services/supplier.service';
import {StockPalletSummary} from '@model/stock-pallet-summary';
import {StockPalletService} from '@services/stock-pallet.service';
import {Zone} from '@model/zone';
import {ZoneService} from "@services/zone.service";
import {StockPallet} from "@model/stock-pallet";
import {PreparationStockSelection} from "@model/preparation-stock-selection";
import {InternalBatchSearch} from "@model/search/internal-batch-search";
import {SupplierBatchSearch} from "@model/search/supplier-batch-search";
import {copyCommonFields} from "@model/mapping-utils";
import {StockSummary} from "@model/stock-summary";
import {StockGroupService} from "@services/stock-group.service";

type StockPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class StockService {

  #httpClient = inject(HttpClient);
  #injector = inject(Injector);
  #warehouseService = inject(WarehouseService);
  #companyService = inject(CompanyService);
  #articleService = inject(ArticleService);
  #supplierService = inject(SupplierService);
  #palletService = inject(StockPalletService);
  #zoneService = inject(ZoneService);

  getStock$(stockId: number): Observable<Stock> {
    return this.#httpClient.get<StockDto>(`${environment.apiUrl}/stocks/${stockId}`)
      .pipe(
        map(stockDto => this.mapToStock(stockDto)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  getStockByPallet$(pallet: StockPallet): Observable<Stock | undefined> {
    return this.#httpClient.get<StockDto>(`${environment.apiUrl}/pallets/${pallet.id}/stock`)
      .pipe(
        map(stockDto => this.mapToStock(stockDto)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(undefined);
          }
          // Rethrow the error for other error status codes
          throw error;
        }),
        shareReplay(),
      );
  }

  findStock(stockSearch: StockSearch, pagination?: StockPagination): Observable<Page<Stock>> {
    const stockSearchDto = this.mapToStockSearchDto(stockSearch);
    return this.#httpClient.post<Page<StockDto>>(`${environment.apiUrl}/stocks/search`, stockSearchDto, {params: pagination})
      .pipe(map(stockPage => {
        const stockList: Stock[] = stockPage.content.map(stock => this.mapToStock(stock));
        return {
          ...stockPage,
          content: stockList
        }
      }));
  }

  getStockSummary$(stockSearch: StockSearch, injector: Injector): Observable<StockSummary> {
    const stockSearchDto = this.mapToStockSearchDto(stockSearch);

    return this.#httpClient.post<StockSummaryDto>(`${environment.apiUrl}/stocks/search/summary`, stockSearchDto)
      .pipe(
        map(stockSummaryDto => this.mapToStockSummary(stockSummaryDto)),
      );
  }

  findInternalBatches(internalBatchSearch: InternalBatchSearch, pagination?: StockPagination): Observable<Page<string>> {
    const internalBatchSearchDto = this.mapToInternalBatchSearchDto(internalBatchSearch);
    return this.#httpClient.post<Page<string>>(`${environment.apiUrl}/stocks/internal-batch/search`, internalBatchSearchDto, {params: pagination});
  }

  findSupplierBatches(supplierBatchSearch: SupplierBatchSearch, pagination?: StockPagination): Observable<Page<string>> {
    const supplierBatchSearchDto = this.mapToSupplierBatchSearchDto(supplierBatchSearch);
    return this.#httpClient.post<Page<string>>(`${environment.apiUrl}/stocks/supplier-batch/search`, supplierBatchSearchDto, {params: pagination});
  }

  mapToStockSummary(stockSummaryDto: StockSummaryDto): StockSummary {
    const commonFields: StockSummaryDto | StockSummary = copyCommonFields(stockSummaryDto, []);

    return {...commonFields};
  }

  #loadArticle$(articleId: string) {
    return this.#articleService.getArticle$(articleId);
  }

  #loadWarehouse$(warehouseId: number) {
    return this.#warehouseService.getWarehouse$(warehouseId);
  }

  #loadCompany$(companyId: string) {
    return this.#companyService.getCompany$(companyId);
  }

  #loadSupplier$(supplierId: string) {
    return this.#supplierService.getSupplier$(supplierId);
  }

  #loadPallet$(palletId: string) {
    return this.#palletService.getStockPallet$(palletId);
  }

  #loadPalletSummary$(stockId: number): Observable<StockPalletSummary> {
    return this.#httpClient.get<StockPalletSummaryDto>(`${environment.apiUrl}/stocks/${stockId}/pallet-summary`)
      .pipe(
        map(stockDto => this.mapToStockPalletSummary(stockDto)),
        shareReplay()
      );
  }

  #loadPreparationZone$(preparationZoneId?: string): Observable<Zone | undefined> {
    return preparationZoneId ? this.#zoneService.find$(preparationZoneId) : of(undefined);
  }

  mapToStockPalletSummary(stockPalletSummaryDto: StockPalletSummaryDto): StockPalletSummary {
    return {
      ...stockPalletSummaryDto,
    };
  }

  mapToStock(stockDto: StockDto): Stock {
    return {
      ...stockDto,
      article$: this.#loadArticle$(stockDto.articleId),
      warehouse$: this.#loadWarehouse$(stockDto.warehouseId),
      company$: this.#loadCompany$(stockDto.companyId),
      supplier$: this.#loadSupplier$(stockDto.supplierId),
      pallet$: this.#loadPallet$(stockDto.palletId),
      palletSummary$: this.#loadPalletSummary$(stockDto.id),
      preparationZone$: this.#loadPreparationZone$(stockDto.preparationZoneId),
    };
  }

  mapToStockDto(stock: Stock): Observable<StockDto> {
    const preparationZone$ = stock.preparationZone$.pipe(defaultIfEmpty(undefined));
    return combineLatest([stock.article$, stock.pallet$, stock.warehouse$, stock.company$, stock.supplier$, preparationZone$])
      .pipe(map(([article, pallet, warehouse, company, supplier, preparationZone]) =>
        ({
          id: stock.id,
          palletId: pallet?.id,
          receptionDate: stock.receptionDate,
          quantity: stock.quantity,
          expirationDate: stock.expirationDate,
          bestBeforeDate: stock.bestBeforeDate,
          unitWeight: stock.unitWeight,
          deliverBeforeDate: stock.deliverBeforeDate,
          fullDescription: stock.fullDescription,
          unitsPerParcel: stock.unitsPerParcel,
          unitGrossWeight: stock.unitGrossWeight,
          sourceRecept: stock.sourceRecept,
          location: stock.location,
          preparationZoneId: preparationZone?.location,
          temporary: stock.temporary,
          articleId: article?.id,
          warehouseId: warehouse?.id,
          companyId: company?.id,
          supplierId: supplier?.id,
          internalBatch: stock.internalBatch,
          supplierBatch: stock.supplierBatch,
        })));
  }

  mapToStockSearchDto(stockSearch: StockSearch): StockSearchDto {
    const commonFields: StockSearchDto | StockSearch = copyCommonFields(stockSearch, ['warehouseSearch', 'articleSearch', 'internalBatchSearch', 'supplierBatchSearch', 'palletSearch', 'preparationZone', 'excludedPreparation', 'stockGroupSearch']);

    const stockGroupService = this.#injector.get(StockGroupService);

    return {
      ...commonFields,
      warehouseSearchDto: stockSearch.warehouseSearch && this.#warehouseService.mapToWarehouseSearchDto(stockSearch.warehouseSearch),
      articleSearchDto: stockSearch.articleSearch && this.#articleService.mapToArticleSearchDto(stockSearch.articleSearch),
      internalBatchSearchDto: stockSearch.internalBatchSearch && this.mapToInternalBatchSearchDto(stockSearch.internalBatchSearch),
      supplierBatchSearchDto: stockSearch.supplierBatchSearch && this.mapToInternalBatchSearchDto(stockSearch.supplierBatchSearch),
      stockPalletSearchDto: stockSearch.palletSearch && this.#palletService.mapToStockPalletSearchDto(stockSearch.palletSearch),
      preparationZoneId: stockSearch.preparationZone?.location,
      excludedPreparationId: stockSearch.excludedPreparation?.id,
      stockGroupSearchDto: stockSearch.stockGroupSearch && stockGroupService.mapToStockGroupSearchDto(stockSearch.stockGroupSearch),
    }
  }

  mapToInternalBatchSearchDto(internalBatchSearch: InternalBatchSearch): InternalBatchSearchDto {
    return {
      ...internalBatchSearch,
      stockSearchDto: internalBatchSearch.stockSearch && this.mapToStockSearchDto(internalBatchSearch.stockSearch)
    }
  }

  mapToSupplierBatchSearchDto(supplierBatchSearch: SupplierBatchSearch): SupplierBatchSearchDto {
    return {
      ...supplierBatchSearch,
      stockSearchDto: supplierBatchSearch.stockSearch && this.mapToStockSearchDto(supplierBatchSearch.stockSearch)
    }
  }

  moveStockOutOfPreparationZone(stockToMove: PreparationStockSelection[]) {
    const movedStocks = stockToMove
      .map(stock =>
        stock.stock$.pipe(take(1), map(stock => {
          stock.preparationZone$ = EMPTY;
          stock.temporary = false;

          return stock;
        })));

    return forkJoin(movedStocks).pipe(
      mergeMap(stocks => this.#saveAllStocks(stocks))
    );
  }

  #saveAllStocks(stocks: Stock[]): Observable<Stock[]> {
    const stockDtoUpdateRequests$ = stocks.map(stock => this.#saveStock(stock));
    return forkJoin(stockDtoUpdateRequests$);
  }

  #saveStock(stock: Stock): Observable<Stock> {
    const stockDto$ = this.mapToStockDto(stock);
    return stockDto$.pipe(
      mergeMap(stockDto => this.#saveStockDto(stockDto)),
      map(stockDto => this.mapToStock(stockDto))
    );
  }

  #saveStockDto(stockDto: StockDto): Observable<StockDto> {
    return this.#httpClient.put<StockDto>(`${environment.apiUrl}/stocks/${stockDto.id}`, stockDto);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organization} from '@model/organization';
import { TableModule } from 'primeng/table';
import { PrimeTemplate } from 'primeng/api';
import { NgIf, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'foodbank-fead-planning-quantity-cell-editor',
    templateUrl: './fead-planning-quantity-cell-editor.component.html',
    styleUrls: ['./fead-planning-quantity-cell-editor.component.scss'],
    imports: [TableModule, PrimeTemplate, NgIf, FormsModule, InputTextModule, TooltipModule, DecimalPipe]
})
export class FeadPlanningQuantityCellEditorComponent implements OnInit {

  @Input() quantity!: number;
  @Input() organization: Organization | null | undefined;
  @Input() period!: string;
  @Output() quantityChange = new EventEmitter<number>();

  isPreferredWeekForDelivery: boolean = true;

  ngOnInit() {
    if (this.organization) {
    this.isPreferredWeekForDelivery = this.organization.isPreferredWeekForDelivery(this.period);
    }
  }

  updateQuantity(quantity: number) {
    this.quantity = quantity;
    this.quantityChange.emit(quantity);
  }
}

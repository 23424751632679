import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, map, mergeMap, Observable} from 'rxjs';
import {ArticleSearch} from '@model/search/article-search';
import {ArticleService} from '@services/article.service';
import {Article} from '@model/article';
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {ArticleComponent} from "@components/article/article.component";

@Component({
    selector: 'foodbank-article-multi-selection',
    templateUrl: './article-multi-selection.component.html',
    styleUrls: ['./article-multi-selection.component.scss'],
    imports: [
        MultiSelectModule,
        FormsModule,
        AsyncPipe,
        ArticleComponent,
        NgIf,
        NgForOf
    ]
})
export class ArticleMultiSelectionComponent implements OnChanges {

  @Input()
  selectedArticles: Article[] = [];
  @Input()
  articleSearch: ArticleSearch | undefined;
  @Output()
  onArticleSelected: EventEmitter<Article[]> = new EventEmitter<Article[]>();

  articleSearch$: BehaviorSubject<ArticleSearch> = new BehaviorSubject<ArticleSearch>({})
  articles$: Observable<Article[]> | undefined | null;

  constructor(private articleService: ArticleService) {
    this.articles$ = this.articleSearch$.pipe(
      distinctUntilChanged(this.isSameSearch),
      mergeMap(articleSearch => this.articleService.findArticles$(articleSearch, {page: 0, size: 1000})),
      map(page => page.content)
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['articleSearch'] && this.articleSearch) {
      this.articleSearch$.next(this.articleSearch);
    }
  }

  select(selectedArticles: Article[]) {
    this.selectedArticles = selectedArticles;
    this.onArticleSelected.emit(selectedArticles);
  }

  clear() {
    this.selectedArticles = [];
    this.onArticleSelected.emit([]);
  }

  private isSameSearch(search1: ArticleSearch, search2: ArticleSearch): boolean {
    const json1 = JSON.stringify(search1);
    const json2 = JSON.stringify(search2);
    return json1 === json2;
  }

  protected readonly console = console;
}

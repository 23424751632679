@let page = transferRequestPage();
@let content = page?.content || [];
<p-table #dt
         [value]="content"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="page?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoadEvent($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         selectionMode="single"
         [multiSortMeta]="[{ field: 'CLOSED', order: 1 }, { field: 'DATE', order: -1 }, { field: 'ID', order: 1 }]"
>

  <ng-template pTemplate="caption">
    <div class="field grid">
      <h2 class="col-2 mb-2 md:col-1" i18n>Transfer requests</h2>
      <div class="col-2 md:col-1">
        <p-button class="col-fixed mt-1" icon="pi pi-plus" [rounded]="true" (onClick)="createTransferRequest()"/>
      </div>
    </div>

  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th i18n></th>
      <th i18n [pSortableColumn]="sortFieldName('ID')">
        #
        <p-sortIcon field="sortFieldName('ID')"/>
      </th>
      <th i18n>
        Company
      </th>
      <th i18n>
        Source warehouse
        <p-sortIcon field="SOURCE_WAREHOUSE"/>
      </th>
      <th i18n [pSortableColumn]="sortFieldName('DATE')">
        Date
        <p-sortIcon field="sortFieldName('DATE')"/>
      </th>
      <th i18n>
        User
      </th>
      <th i18n [pSortableColumn]="sortFieldName('CLOSED')">
        Closed
        <p-sortIcon field="sortFieldName('CLOSED')"/>
      </th>
      <th i18n>
        Comment
      </th>
    </tr>
    <tr>
      <th>
      </th>
      <th>
      </th>
      <th>
        <p-columnFilter field="company" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-company-selection ngDefaultControl [(ngModel)]="selectedCompany" [companySearch]="companySearch()"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="sourceWarehouse" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-warehouse-selection ngDefaultControl [(ngModel)]="selectedSourceWarehouse" [warehouseSearch]="warehouseSearch()"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th>
        <p-columnFilter field="sourceWarehouse" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <p-triStateCheckbox [(ngModel)]="showClosed" inputId="tricheckbox"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="sourceWarehouse" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <input pInputText [(ngModel)]="commentContains">
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row>
    @let transferRequest = typing(row);
    <tr>
      <td>
        <p-button type="button" icon="pi pi-folder-open" (click)="showTransferRequest(transferRequest)"></p-button>
      </td>
      <td>
        {{ transferRequest.id }}
      </td>
      <td>
        <foodbank-company ngDefaultControl [ngModel]="transferRequest.company()" [showName]="true"/>
      </td>
      <td>
        <foodbank-warehouse ngDefaultControl [ngModel]="transferRequest.warehouse()"/>
      </td>
      <td>
        {{ transferRequest.date | date: 'dd/MM/yyyy' }}
      </td>
      <td>
        <foodbank-user ngDefaultControl [ngModel]="transferRequest.user()"/>
      </td>
      <td>
        <p-checkbox [binary]="true"
                    [ngModel]="transferRequest.closed"
                    [disabled]="true"
        />
      </td>
      <td style="width: 100px">
        {{ transferRequest.comment }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <span i18n>No transfer requests found.</span>
  </ng-template>
</p-table>

import {Component, computed, inject, Injector, input, linkedSignal, model, OnInit, signal, Signal, WritableSignal} from '@angular/core';
import {ArticleService} from '@services/article.service';
import {map, of} from "rxjs";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {Page} from '@typedefs/page';
import {ArticleSearch} from '@model/search/article-search';
import {Article} from '@model/article';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ArticleEditComponent} from '@components/article/article-edit/article-edit.component';
import {FeadCampaignComponent} from '@components/fead-campaign/fead-campaign.component';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Pagination} from '@services/pagination';
import {ArticleStorageCondition} from "@model/article-storage-condition";
import {ArticleCategory} from "@model/article-category";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {SupplierSearch} from "@model/search/supplier-search";
import {MovementType} from "@model/movement-type";
import {Supplier} from "@model/supplier";
import {Warehouse} from "@model/warehouse";
import {UserService} from "@services/user.service";
import {Movement} from "@model/movement";
import {MovementKind} from "@typedefs/stock-rest";
import {toSignal} from "@angular/core/rxjs-interop";
import {MovementTypeSearch} from "@model/search/movement-type-search";
import {MovementCategory} from "@model/movement-category";
import {MovementTypeService} from "@services/movement-type.service";
import {MessageService, PrimeTemplate} from "primeng/api";
import {StockAdjustment} from "@model/stock-adjustment";
import {StockAdjustmentService} from "@services/stock-adjustment.service";
import {ArticleStorageConditionSearch} from "@model/search/article-storage-condition-search";
import {ArticleCategorySearch} from "@model/search/article-category-search";
import {InputTextModule} from 'primeng/inputtext';
import {PanelModule} from 'primeng/panel';
import {AsyncPipe, NgIf} from '@angular/common';
import {MovementTypeSingleSelectionComponent} from '../movement-type/selection/single/movement-type-single-selection.component';
import {FormsModule} from '@angular/forms';
import {WarehouseSingleSelectionComponent} from '../warehouse/selection/single/warehouse-single-selection.component';
import {SupplierSelectionComponent} from '../supplier/selection/single/supplier-selection.component';
import {DatePickerComponent} from '../date/date-picker/date-picker.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ArticleMultiSelectionComponent} from './selection/multi/article-multi-selection.component';
import {ArticleCategorySelectionComponent} from '../article-category/selection/multi/article-category-selection.component';
import {ArticleStorageConditionSelectionComponent} from '../article-storage-condition/article-storage-condition-selection.component';
import {CheckboxModule} from 'primeng/checkbox';
import {ArticleComponent} from './article.component';
import {Button, ButtonDirective} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {ArticleCategoryComponent} from '../article-category/article-category.component';
import {ArticleStorageConditionComponent} from '../article-storage-condition/article-storage-condition.component';
import {Ripple} from 'primeng/ripple';
import {Organization} from "@model/organization";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {StockGroupSearch} from "@model/search/stock-group-search";
import {BlockUIModule} from "primeng/blockui";
import {derivedAsync} from "ngxtension/derived-async";
import {NumberComponent} from "@components/number/number.component";
import {ArticleWithStockSummary} from "@model/article-with-stock-summary";
import {StockSearch} from "@model/search/stock-search";

@Component({
  selector: 'foodbank-articles',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, PanelModule, NgIf, MovementTypeSingleSelectionComponent, FormsModule, WarehouseSingleSelectionComponent, SupplierSelectionComponent, DatePickerComponent, ToggleButtonModule, ArticleMultiSelectionComponent, ArticleCategorySelectionComponent, ArticleStorageConditionSelectionComponent, CheckboxModule, ArticleComponent, Button, TooltipModule, ArticleCategoryComponent, ArticleStorageConditionComponent, ButtonDirective, Ripple, AsyncPipe, OrganizationSingleSelectionComponent, BlockUIModule, NumberComponent]
})
export class ArticleListComponent implements OnInit {

  bulkMovementType = model<MovementType>();
  bulkMovementTypeOverride: WritableSignal<MovementType | undefined>;
  bulkMovementKind = input<MovementKind>();
  bulkMovementCategory = model<MovementCategory>();
  bulkMovementActive = model(false);
  canExitBulkMovementMode = model(true);
  bulkMovementNeedsTargetWarehouse: Signal<boolean>;
  bulkMovementNeedsSupplier: Signal<boolean>;
  bulkMovementNeedsOrganization: Signal<boolean>;
  bulkMovementWarehouse = model<Warehouse>();
  bulkMovementWarehouseOverride: WritableSignal<Warehouse | undefined>;
  bulkMovementTargetWarehouse = model<Warehouse>();
  bulkMovementComment = model<string>();
  bulkMovementSupplier = model<Supplier>();
  bulkMovementOrganization = model<Organization>();
  bulkMovementDate = model(new Date());

  bulkMovementTypeSearch: Signal<MovementTypeSearch>;
  bulkMovementWarehouseSearch: Signal<WarehouseSearch>;
  bulkMovementTargetWarehouseSearch: Signal<WarehouseSearch>;
  bulkMovementSupplierSearch: Signal<SupplierSearch>;

  bulkMovementValid: Signal<boolean>;

  // TODO: be positive!
  bulkMovementNotReady = computed(() => this.bulkMovementActive() && !this.bulkMovementValid());

  pagination: WritableSignal<Pagination>;
  articlePage: WritableSignal<Page<ArticleWithStockSummary> | undefined>;

  articleSearch: Signal<ArticleSearch>;

  bulkFood = computed(() => this.bulkMovementCategory() === 'BULK_FOOD');

  filterArticlesSearch: Signal<ArticleSearch>;
  filterArticleCategorySearch: Signal<ArticleCategorySearch>;
  filterArticleStorageConditionSearch: Signal<ArticleStorageConditionSearch>;

  articlesFilter = signal<Article[]>([]);
  articleStorageConditionsFilter = signal<ArticleStorageCondition[]>([]);
  articleCategoriesFilter = signal<ArticleCategory[]>([]);
  stockSearch: Signal<StockSearch | undefined>;
  stockGroupSearch: Signal<StockGroupSearch | undefined>;

  #dialogRef?: DynamicDialogRef;
  #articleService = inject(ArticleService);
  #dialogService = inject(DialogService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #stockAdjustmentService = inject(StockAdjustmentService);
  #injector = inject(Injector);

  #movementTypeService = inject(MovementTypeService);

  #messageService = inject(MessageService);

  constructor() {
    const defaultPagination = this.#paginationService.getDefaultPagination();
    this.pagination = signal(defaultPagination);

    this.articleSearch = computed(() => ({
      articles: this.articlesFilter(),
      articleCategorySearch: {
        articleCategories: this.articleCategoriesFilter(),
      },
      articleStorageConditionSearch: {
        articleStorageConditions: this.articleStorageConditionsFilter(),
      },
      stockGroupSearch: this.stockGroupSearch(),
      bulkFood: this.bulkFood()
    }));
    this.stockSearch = computed(() => ({warehouseSearch: {warehouses: this.bulkMovementWarehouseOverride() ? [this.bulkMovementWarehouseOverride()!] : undefined}}));
    const articlePage = derivedAsync(() => this.bulkMovementNotReady() || !this.stockSearch() ? undefined : this.#articleService.findArticleWithSummaryPage$(this.articleSearch(), this.stockSearch()!, this.pagination(), this.#injector), {injector: this.#injector})
    this.articlePage = linkedSignal(() => articlePage());

    this.filterArticlesSearch = computed(() => ({
      bulkFood: this.bulkFood(),
      articleCategorySearch: {
        articleCategories: this.articleCategoriesFilter(),
      },
      articleStorageConditionSearch: {
        articleStorageConditions: this.articleStorageConditionsFilter(),
      },
    }));

    this.stockGroupSearch = computed(() => !this.bulkMovementWarehouseOverride() ? undefined : {stockSearch: this.stockSearch()})

    this.filterArticleCategorySearch = computed(() => ({}));

    this.filterArticleStorageConditionSearch = computed(() => ({}));

    const currentUserCompany = this.#userService.getCurrentUserCompany()

    this.bulkMovementTypeSearch = computed(() => ({
      forBulkFood: this.bulkFood()
    }));

    this.bulkMovementWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.bulkMovementTargetWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.bulkMovementSupplierSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.bulkMovementNeedsTargetWarehouse = computed(() => {
      const movementKind = this.bulkMovementTypeOverride()?.movementKind;
      return movementKind === 'INTER_WAREHOUSE_TRANSFER';
    });

    this.bulkMovementNeedsSupplier = computed(() => {
      const movementKind = this.bulkMovementTypeOverride()?.movementKind;
      return movementKind === 'RECEIPT' || movementKind === 'RECEIPT_CORRECTION';
    });

    this.bulkMovementNeedsOrganization = computed(() => {
      const movementKind = this.bulkMovementTypeOverride()?.movementKind;
      return movementKind === 'EXPEDITION' || movementKind === 'FROZEN_EXPEDITION';
    });

    this.bulkMovementValid = computed(() => {
      const isMovementTypeUndefined = this.bulkMovementTypeOverride() === undefined;
      const isSupplierUndefined = this.bulkMovementSupplier() === undefined;
      const isOrganizationUndefined = this.bulkMovementOrganization() === undefined;
      const isSourceWarehouseUndefined = this.bulkMovementWarehouseOverride() === undefined;
      const isTargetWarehouseUndefined = this.bulkMovementTargetWarehouse() === undefined;

      const isSupplierNotRequiredOrProvided = !this.bulkMovementNeedsSupplier() || !isSupplierUndefined
      const isTargetWarehouseNotRequiredOrProvided = !this.bulkMovementNeedsTargetWarehouse() || !isTargetWarehouseUndefined;
      const isOrganizationNotRequiredOrProvided = !this.bulkMovementNeedsOrganization() || !isOrganizationUndefined

      return !isMovementTypeUndefined &&
        !isSourceWarehouseUndefined &&
        isSupplierNotRequiredOrProvided &&
        isTargetWarehouseNotRequiredOrProvided &&
        isOrganizationNotRequiredOrProvided
    });

    const bulkMovementTypeFromKindInput = derivedAsync(() => !this.bulkMovementKind() ? of(this.bulkMovementType()) : this.#movementTypeService.getMovementType$(this.bulkMovementKind()!));
    this.bulkMovementTypeOverride = linkedSignal(() => bulkMovementTypeFromKindInput())
    const defaultWarehouse = toSignal(this.#userService.getDefaultWarehouse$());
    this.bulkMovementWarehouseOverride = linkedSignal(() => this.bulkMovementWarehouse() ? this.bulkMovementWarehouse() : defaultWarehouse());
  }

  ngOnInit() {

  }

  openEditDialog(article: Article) {
    this.#dialogRef = this.#dialogService.open(ArticleEditComponent, {
      header: 'Edit article',
      width: '40%',
      data: {...article}
    });

    this.#dialogRef.onClose.subscribe(_ => this.#reload());
  }

  openFeadCampaignDialog(article: Article) {
    this.#dialogRef = this.#dialogService.open(FeadCampaignComponent, {
      header: 'Fead campaign ' + article.id,
      width: '70%',
      data: {...article}
    });

    this.#dialogRef.onClose.subscribe(_ => this.#reload());
  }

  saveArticleQuantity(articleWithStockSummary: ArticleWithStockSummary, quantity: number) {
    const stockSummary = articleWithStockSummary.stockSummary;
    if (!stockSummary || !this.bulkMovementValid()) {
      return;
    }

    const movementType = this.bulkMovementTypeOverride()!;
    const warehouse = this.bulkMovementWarehouseOverride()!;
    const bulkMovementSupplier = this.bulkMovementSupplier();
    const organization = this.bulkMovementOrganization();
    const targetWarehouse = this.bulkMovementTargetWarehouse();

    const stockAdjustment: StockAdjustment = {
      quantity,
      movementType$: of(movementType),
      targetWarehouse$: of(targetWarehouse),
      supplier$: of(bulkMovementSupplier),
      organization$: of(organization),
      comment: this.bulkMovementComment(),
      dateTime: this.bulkMovementDate()
    };

    const article = articleWithStockSummary.article;
    this.#stockAdjustmentService.createStockAdjustmentForArticle(article, warehouse, stockAdjustment)
      .subscribe(movement => {
        this.#notifyMovement(movement);
        this.#reloadStockSummaryForArticle(article, this.#injector)
      });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  fixTyping(articleWithStockSummary: ArticleWithStockSummary): ArticleWithStockSummary {
    return articleWithStockSummary;
  }

  #reload() {
    // re-instantiate any dependency to re-trigger a search
    this.pagination.set({...this.pagination()});
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  #notifyMovement(movement: Movement) {
    this.#messageService.add({severity: 'success', summary: 'Bulk movements created', detail: `Created movement ${movement.id}.`});
  }

  #reloadStockSummaryForArticle(article: Article, injector: Injector) {
    const articleSearch: ArticleSearch = {
      ...this.articleSearch(),
      articles: [article],
    }
    const singleResultPagination = this.#paginationService.getDefaultPagination(1);
    this.#articleService.findArticleWithSummaryPage$(articleSearch, this.stockSearch()!, singleResultPagination, injector)
      .pipe(
        map(articlePage => articlePage.content?.[0]),
      )
      .subscribe(articleWithStockSummary => this.#updateArticleWithStockSummary(articleWithStockSummary));
  }

  #updateArticleWithStockSummary(updatedArticleWithStockSummary: ArticleWithStockSummary) {
    const articleWithStockSummaryList = this.articlePage()?.content;
    if (articleWithStockSummaryList) {
      const index = articleWithStockSummaryList.findIndex(articleWithStockSummary => articleWithStockSummary.article.id === updatedArticleWithStockSummary.article.id);
      if (index != undefined) {
        const updatedArticleWithStockSummaryList: ArticleWithStockSummary[] = [...articleWithStockSummaryList];
        updatedArticleWithStockSummaryList[index] = updatedArticleWithStockSummary;
        this.articlePage.update(articlePage => articlePage ? ({
            ...articlePage,
            content: updatedArticleWithStockSummaryList
          }) : undefined
        );
      }
    }
  }
}

import {Component, Input} from '@angular/core';
import {Preparation} from '@model/preparation';
import { RouterLink } from '@angular/router';
import {NgIf, AsyncPipe, DatePipe} from '@angular/common';
import { WarehouseComponent } from '../../../warehouse/warehouse.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'foodbank-fead-preparation',
    templateUrl: './fead-preparation.component.html',
    styleUrls: ['./fead-preparation.component.scss'],
    imports: [RouterLink, NgIf, WarehouseComponent, FormsModule, AsyncPipe, DatePipe]
})
export class FeadPreparationComponent {

  @Input()
  preparation!: Preparation;
  @Input()
  showDetails = true;
  @Input()
  showId = true;

}

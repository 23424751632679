import { Component, computed, inject, Injector, Input, linkedSignal, model, OnInit, Signal } from '@angular/core';
import { ReceptionItem } from '@model/reception-item';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Article } from '@model/article';
import { BehaviorSubject, debounceTime, filter, map, mergeMap, pipe, shareReplay, take } from 'rxjs';
import { ReceptionItemService } from "@services/reception-item.service";
import { ReceptionItemSummary } from "@model/reception-item-summary";
import { ArticleSearch } from '@model/search/article-search';
import { DatePickerComponent, FoodbankDatePickerEvent } from '@components/date/date-picker/date-picker.component';
import { pipeSignalWithDefault } from "@util/foodbanks-signal-rxjs-interop";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ArticleSingleSelectionComponent } from "@components/article/selection/single/article-single-selection.component";
import { TooltipModule } from "primeng/tooltip";
import { ReturnableSingleSelectionComponent } from "@components/returnable/selection/single/returnable-single-selection.component";
import { DividerModule } from "primeng/divider";
import { CardModule } from "primeng/card";
import { ReceptionPalletClusterTableComponent } from '../reception-pallet-cluster-table/reception-pallet-cluster-table.component';
import { BlockUIModule } from "primeng/blockui";
import { Button } from "primeng/button";

@Component({
    selector: 'foodbank-reception-item-edit',
    templateUrl: './reception-item-edit.component.html',
    styleUrls: ['./reception-item-edit.component.scss'],
  imports: [NgIf, FormsModule, ArticleSingleSelectionComponent, TooltipModule, DatePickerComponent, ReturnableSingleSelectionComponent, DividerModule, CardModule, ReceptionPalletClusterTableComponent, BlockUIModule, Button]
})
export class ReceptionItemEditComponent implements OnInit {

  receptionItem = model<ReceptionItem>();
  @Input() isNew: boolean = false;

  isBulkFood = computed(() => this.#receptionItemService.isBulkFood(this.receptionItem()?.article.value()));
  receptionItemSummary = linkedSignal(() => this.receptionItem()?.summary.value())
  receptionItemDetailsAreValid = computed(() => this.isReceptionItemValid(this.receptionItem(), this.receptionItemSummary()));
  articleSearch$: Signal<ArticleSearch>

  private saveReceptionItemSink$ = new BehaviorSubject<ReceptionItem | undefined>(undefined);
  private savedUnitsPerParcel: number = 0;

  #receptionItemService = inject(ReceptionItemService);
  #injector = inject(Injector);

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<{ isNew: boolean, receptionItem: ReceptionItem }>,
  ) {
    let initialReceptionItem: ReceptionItem;
    if (this.config.data) {
      initialReceptionItem = {
        ...this.config.data.receptionItem,
        unitWeight: this.config.data.receptionItem.unitWeight ?? 0,
      }
      this.isNew = this.config.data.isNew || false;
    } else {
      initialReceptionItem = {palletQuantity: 1} as ReceptionItem;
    }

    this.receptionItem.set({
      ...initialReceptionItem,
      unitWeight: initialReceptionItem.unitWeight ?? 0
    });

    this.articleSearch$ = pipeSignalWithDefault(this.receptionItem, pipe(
      filter(receptionItem => !!receptionItem),
      mergeMap(receptionItem => receptionItem.reception$),
      mergeMap(reception => reception.supplier$),
      take(1),
      map(supplier => ({fead: supplier.fead}))
    ), {fead: false});

  }

  ngOnInit() {
    this.saveReceptionItemSink$
      .pipe(
        debounceTime(700),
        filter((receptionItemFormInput) => this.areArticleDetailsValid(receptionItemFormInput)),
      )
      .subscribe(receptionItemFormInput => this.doSave(receptionItemFormInput!));
  }

  save(receptionItem: ReceptionItem) {
    this.saveReceptionItemSink$.next(receptionItem);
  }

  private doSave(receptionItem: ReceptionItem) {
    const result = this.#receptionItemService.saveReceptionItem$(receptionItem, this.#injector)
      .pipe(shareReplay())

    result.subscribe(receptionItem => {
      console.debug('Saved ReceptionItem:', receptionItem);
      // reload only the summary because I don't want the view to be completely refreshed
      this.receptionItem()!.summary.reload()
    });

    return result;
  }

  saveAndClose(receptionItem: ReceptionItem) {
    this.doSave(receptionItem)
      .subscribe(receptionItem => this.ref.close(receptionItem));
  }

  closeWithoutSaving() {
    if (!this.isNew) {
      this.ref.close({});
      return;
    }

    this.#receptionItemService.deleteReceptionItem$(this.receptionItem()!)
      .subscribe(_ => this.ref.close({}));
  }

  saveUnitWeight(unitWeight: number, receptionItem: ReceptionItem) {
    receptionItem.unitWeight = unitWeight ?? 0;
    if ((!unitWeight && unitWeight !== 0) || unitWeight === 0) {
      if (receptionItem.unitsPerParcel !== 0) {
      this.savedUnitsPerParcel = receptionItem.unitsPerParcel;
      }
      receptionItem.unitsPerParcel = 0;
    } else if (receptionItem.unitsPerParcel === 0) {
      receptionItem.unitsPerParcel = this.savedUnitsPerParcel;
    }
    receptionItem.unitGrossWeight = receptionItem.unitWeight;

    this.save(receptionItem)
  }

  selectArticle(selectedArticle: Article | undefined, receptionItemInput: ReceptionItem) {
    receptionItemInput.unitWeight = selectedArticle?.feadUnitWeight || 0;
    receptionItemInput.unitGrossWeight = selectedArticle?.feadUnitGrossWeight || 0;
    receptionItemInput.unitsPerParcel = selectedArticle?.feadUnitsPerParcel || 0;

    this.save(receptionItemInput);
  }

  selectExpirationDate(event: FoodbankDatePickerEvent, receptionItemFormInput: ReceptionItem) {
    receptionItemFormInput.expirationDate = event.utcValue as Date;
    this.save(receptionItemFormInput);
  }

  selectBestBeforeDate(event: FoodbankDatePickerEvent, receptionItemFormInput: ReceptionItem) {
    receptionItemFormInput.bestBeforeDate = event.utcValue as Date;
    this.save(receptionItemFormInput);
  }

  private isReceptionItemValid(input: ReceptionItem | undefined, summary: ReceptionItemSummary | undefined): boolean {
    const areArticleDetailsValid = this.areArticleDetailsValid(input);
    if (!areArticleDetailsValid) {
      return false;
    }

    if (!summary) {
      return false;
    }

    const isBulkFood = this.#receptionItemService.isBulkFood(input?.article.value());
    return summary.palletCount > 0 && (isBulkFood || input?.unitWeight === 0 || summary.unitCount > 0);
  }

  areArticleDetailsValid(receptionItem: ReceptionItem | undefined): boolean {
    if (!receptionItem) {
      return false;
    }

    const bestBeforeOrExpirationDateIsProvided = (receptionItem.bestBeforeDate || receptionItem.expirationDate);
    const isBulkFood = receptionItem.article.value()?.bulkFood ?? false;

    const bulkFoodOrUnitsPerParcelProvided = isBulkFood || (receptionItem.unitWeight === 0 && receptionItem.unitsPerParcel === 0) || receptionItem.unitsPerParcel > 0
    const bulkFoodUnitsOrUnitWeightProvided = isBulkFood || receptionItem.unitWeight >= 0

    return !!receptionItem.article.value()
      && !!bestBeforeOrExpirationDateIsProvided
      && bulkFoodOrUnitsPerParcelProvided
      && bulkFoodUnitsOrUnitWeightProvided;
  }

}

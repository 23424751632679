import {Component, input, model} from '@angular/core';
import {Company} from "@model/company";

@Component({
  selector: 'foodbank-company',
  templateUrl: './company.component.html',
  standalone: true,
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {
  ngModel = model<Company>();
  showId = input(true);
  showName = input(true);
}
